/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import { getExpressParcelConnection } from '../../utils/api';
import { LocationIcon } from '../Icon';
import { dateToTimezoneHour } from '../../utils/date';
import StopMap from './StopMap';
import { useTranslation } from 'react-i18next';

const LOCATION_SIZE = 24;
const LINE_WIDTH = 2;
const BALL_SIZE = LINE_WIDTH * 4;
const LINE_OFFSET_X = 8;

export const usePlace = ({ place: hookPlace, connection }) => {
  const [stop, setStop] = useState(null);
  const details = useRef(null);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const callback = useCallback(
    async (argPlace) => {
      try {
        const place = hookPlace || argPlace;
        if (!place) {
          setStop(null);
          return;
        }
        if (!details.current) {
          setLoading(true);
          details.current = await getExpressParcelConnection({
            id: connection.id,
            language,
          });
          setLoading(false);
        }
        const connectionDetails = details.current;
        if (!connectionDetails) return;
        const stop = connectionDetails.partTrips[0]?.route?.find((it) => `${it.digiStopId}` === `${place.digiStopId}`);
        if (!stop) return;
        setStop((current) => (current && `${current.digiStopId}` === `${stop.digiStopId}` ? null : stop));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setStop, details, hookPlace, language, connection]
  );
  return { stop, details, loading, callback };
};

const ConnectionPlace = ({ place, connection }) => {
  const { theme } = useThemeUI();
  const { stop, details, callback: handleSelectPlace } = usePlace({ place, connection });
  return (
    <Box>
      {!!place.dateTime && (
        <Box sx={{ fontSize: 2, color: 'secondary', fontWeight: 'medium' }}>{dateToTimezoneHour(place.dateTime)}</Box>
      )}
      <Box sx={{ fontSize: 2, color: 'secondary', mt: 1 }}>{place.placeName}</Box>
      <Flex
        sx={{
          backgroundColor: 'grayLighter',
          borderRadius: 1,
          my: 2,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            p: 2,
            pl: 3,
          }}
        >
          <Box sx={{ fontSize: 2, color: 'secondary', fontWeight: 'medium' }}>{place.stopAreaName}</Box>
          {place.stopName && <Box sx={{ fontSize: 2, color: 'secondary' }}>, {place.stopName}</Box>}
        </Flex>
        <Box onClick={handleSelectPlace} sx={{ p: 3, cursor: 'pointer' }}>
          <LocationIcon color={theme.colors.primary} width={LOCATION_SIZE} height={LOCATION_SIZE} />
        </Box>
      </Flex>
      <StopMap stop={stop} connection={details.current} />
    </Box>
  );
};

export default ({ connection }) => {
  const [lineHeight, setLineHeight] = useState(0);
  const arrivalBox = useRef(null);
  useEffect(() => {
    const newHeight = arrivalBox.current?.offsetTop + 4;
    if (newHeight) {
      setLineHeight(newHeight);
    }
  }, [setLineHeight, arrivalBox]);
  if (!connection) {
    return <></>;
  }
  return (
    <Flex sx={{ flexDirection: 'row' }}>
      <Flex sx={{ position: 'relative', width: BALL_SIZE + 12 }}>
        <Box
          sx={{
            borderRadius: 9999,
            backgroundColor: 'secondary',
            height: BALL_SIZE,
            width: BALL_SIZE,
            position: 'absolute',
            top: `${LINE_OFFSET_X}px`,
            left: 0,
          }}
        />
        <Box
          sx={{
            height: lineHeight,
            width: LINE_WIDTH,
            backgroundColor: 'secondary',
            position: 'absolute',
            top: `${LINE_OFFSET_X}px`,
            left: BALL_SIZE / 2 - LINE_WIDTH / 2 + 0.0001,
          }}
        />
        <Box
          sx={{
            borderRadius: 9999,
            backgroundColor: 'secondary',
            height: BALL_SIZE,
            width: BALL_SIZE,
            position: 'absolute',
            top: lineHeight - BALL_SIZE / 2 + LINE_OFFSET_X,
            left: 0,
          }}
        />
      </Flex>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <ConnectionPlace place={connection.fromPlace} connection={connection} />
        <Box sx={{ height: 24 }} />
        <Box ref={arrivalBox}>
          <ConnectionPlace place={connection.toPlace} connection={connection} />
        </Box>
      </Box>
    </Flex>
  );
};

import { useTranslation } from 'react-i18next';
import i18n from '../localization/i18n';

let id = 0;

export const SEVERITY = {
  INFO: 0,
  WARN: 1,
  ERROR: 2,
};

const maxSimultaneousErrors = 2;
const minErrorTime = 10;

const initialState = [];

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const HIDE_ALL_NOTIFICATIONS = 'HIDE_ALL_NOTIFICATIONS';

export const showNotification =
  (msg, severity = SEVERITY.ERROR, params) =>
  (dispatch, _getState) => {
    return dispatch(showNotificationWithoutTranslation(i18n.t(msg, params), severity));
  };

export const showNotificationWithoutTranslation =
  (msg, severity = SEVERITY.ERROR) =>
  (dispatch, getState) => {
    id += 1;
    const date = +new Date();
    dispatch({
      type: SHOW_NOTIFICATION,
      id,
      date,
      severity,
      msg,
    });
  };

export const hideNotification = (id) => ({
  type: HIDE_NOTIFICATION,
  id,
});

export const hideAllNotifications = () => ({
  type: HIDE_ALL_NOTIFICATIONS,
});

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case SHOW_NOTIFICATION:
      const newNotifications = [...state, params];
      // show max N notifications
      if (newNotifications.length <= maxSimultaneousErrors) {
        return newNotifications;
      } else {
        // remove oldest if it's old enough
        const oldestNotification = state[0];
        const now = +new Date();
        const elapsed = (now - oldestNotification.date) / 1000;
        if (elapsed > minErrorTime) {
          newNotifications.shift();
          return newNotifications;
        } else {
          // ignore newest notification to avoid too many notification in short time
          return state;
        }
      }
    case HIDE_NOTIFICATION:
      return state.filter((n) => n.id !== params.id);
    case HIDE_ALL_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

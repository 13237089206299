/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import Link from './Link';
import { useColors } from '../hooks/useColors';

const isMhCom = process.env.GATSBY_SITE_DOMAIN === 'com';

const NavigationActionLink = ({ current, darkMode, ...props }) => {
  const { hiContrast } = useColors(darkMode);
  const linkColor = props?.color || hiContrast;
  return (
    <Flex
      as={Link}
      partiallyActive={true}
      sx={{
        position: 'relative',
        alignItems: 'center',
        color: ['white', null, null, linkColor],
        mx: 2,
        transition: 'color .25s ease',
        ':hover': {
          color: linkColor,
          textDecoration: ['underline', null, null, null],
        },
        ':hover, &[aria-current="page"]': {
          color: ['white', null, null, linkColor],
        },
        '&::after': {
          position: 'absolute',
          bottom: -1,
          left: 2,
          right: 2,
          height: 2,
          bg: isMhCom ? 'pinkDark' : linkColor,
          content: '""',
          width: current ? [0, 0, 0, 'auto'] : 0,
        },
        '&[aria-current="page"]::after': {
          width: [0, 0, 0, 'auto'],
        },
      }}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

export default NavigationActionLink;

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useCallback, useContext } from 'react';
import { locNavigate } from '../../state/session';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import Spinner from '../Spinner';
import { SearchContext } from './SearchContext';
import { findTrackingInfo } from '../../state/tracking';

const TrackingQuestion = ({ setSearchStatus }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.tracking.fetching);
  const { searchTerm } = useContext(SearchContext);
  // if search term contans 7 and more numbers
  const show = searchTerm.replace(/[^\d]/g, '').length >= 7;
  const trackingId = searchTerm;
  const goToTracking = useCallback(async () => {
    await dispatch(findTrackingInfo(trackingId));
    setSearchStatus(false);
  }, [dispatch, trackingId, setSearchStatus]);
  return show ? (
    <Box
      sx={{
        position: 'absolute',
        top: 'calc(100% + 0.5em)',
        right: 0,
        left: [0, null, null, 'auto'],
        zIndex: 3,
        width: '80vw',
        maxWidth: '30em',
        maxHeight: '80vh',
        p: 3,
        overflowY: 'scroll',
        overflowX: 'hidden',
        bg: 'white',
        borderRadius: 2,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(0,0,0,.08)',
        '-webkit-overflow-scrolling': 'touch',
      }}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: translate('search.trackingConfirmation', { trackingId }),
        }}
        sx={{ mb: 2 }}
      />
      <Button variant="small" onClick={goToTracking}>
        {translate('search.trackingConfirmationButton')}
      </Button>
      {isFetching && (
        <div sx={{ position: 'relative', bottom: '16px' }}>
          <Spinner size="medium" />
        </div>
      )}
    </Box>
  ) : null;
};

export default TrackingQuestion;

import { useStaticQuery, graphql } from 'gatsby';

export const filterByChannel = (edge) => {
  const channels = edge.node.channel;
  return channels === null || channels.includes('www.matkahuolto.fi');
};

export const filterByDomain = (edge) => {
  const isMhCom = process.env.GATSBY_SITE_DOMAIN === 'com';
  const tags = edge?.node?.metadata?.tags?.map((t) => t?.contentful_id) || [];
  const isComTagged = tags?.includes('matkahuoltoCom') || false;
  return isMhCom === isComTagged;
};

export const useBlogPosts = () => {
  const { allContentfulBlogPost } = useStaticQuery(graphql`
    query AllContentfulBlogPostQuery {
      allContentfulBlogPost {
        edges {
          node {
            id
            contentful_id
            metadata {
              tags {
                contentful_id
              }
            }
            slug
            postType
            channel
          }
        }
      }
    }
  `);
  return allContentfulBlogPost.edges.filter(filterByChannel).filter(filterByDomain);
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-58XB99L","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          const cookies = document.cookie.split('; ');\n          const consent = cookies.find((c) => c.startsWith('CookieConsentV2='));\n          const categories = consent ? consent.substr(16).split('%2C') : ['NO'];\n          return { platform: 'gatsby', 'Cookie Consent': categories.join(',') };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Matkahuolto","short_name":"Matkahuolto","start_url":"/","background_color":"#ffffff","theme_color":"#001E60","display":"standalone","icon":"src/images/logo.svg","crossOrigin":"use-credentials","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.kyyti.ride.matkahuolto","url":"https://play.google.com/store/apps/details?id=com.kyyti.ride.matkahuolto"},{"platform":"play","id":"fi.matkahuolto.paketti","url":"https://play.google.com/store/apps/details?id=fi.matkahuolto.paketti"},{"platform":"itunes","id":"1496304929","url":"https://itunes.apple.com/app/reitit-ja-liput/id1496304929"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"70ae47b1635fca3d52bb986c69bb76c9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

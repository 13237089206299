/** @jsx jsx */
import React from 'react';
import { jsx, Flex, Box } from 'theme-ui';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatSize } from '../utils/formatters';
import { imagePathForProduct, SizeCode } from '../state/catalog';
import Tooltip from '../components/Tooltip';
import { InfoIcon } from '../components/Icon';
import moment from 'moment-timezone';

export default ({ product, altProduct, isSelected, isCurrentSize }) => {
  const { t: translate, i18n } = useTranslation();
  const language = i18n.language;
  let { displayName, sizeCode, localizedName, deliveryIncluded } = product;
  const { parcelType } = useSelector((state) => state.parcelFlow);
  const isSeriesProduct = product.quantity && product.quantity > 1;
  let tooltip;
  if (localizedName && localizedName[language]) {
    displayName = localizedName[language];
  }
  if ([SizeCode.REPACK, SizeCode.REFILL].includes(sizeCode)) {
    displayName = translate('buyParcel.repack.nameOnCard');
    tooltip = (
      <Flex sx={{ flexDirection: 'column', div: { my: 2 } }}>
        <div>{translate('buyParcel.repack.tooltip1')}</div>
        <div>{translate('buyParcel.repack.tooltip2')}</div>
        <div>{translate('buyParcel.repack.tooltip3')}</div>
      </Flex>
    );
  }
  let maxSize = product.maxSize && (
    <>
      {translate('buyParcel.product.maxSize')}
      <br />
      {formatSize(product.maxSize)}
    </>
  );
  if (product.maxSideSum) {
    maxSize = <span>{translate('buyParcel.product.flexible', { ...product })}</span>;
  }
  if (sizeCode == SizeCode.BIKE) {
    maxSize = (
      <span
        dangerouslySetInnerHTML={{
          __html: translate('buyParcel.product.bikeSize'),
        }}
      />
    );
  }

  // PAK-719: added "nouto ja toimitus ovelle" label for domestic parcels if delivery / pickup included
  const includesDeliveryAndPickup =
    product?.deliveryIncluded && product?.pickupIncluded && (product?.countryCode === 'FI' || !product?.countryCode);

  const mailboxDelivery = !!product?.mailboxDelivery;
  const specialOffer = useMemo(() => {
    const { specialOffer } = product || {};
    const now = moment();

    return specialOffer &&
      specialOffer.parcelType === parcelType &&
      now.isBetween(specialOffer.startTime, specialOffer.endTime)
      ? specialOffer
      : null;
  }, [product, parcelType]);

  const hasSpecialNote = isCurrentSize || deliveryIncluded || mailboxDelivery;

  return (
    <>
      {/* desktop */}
      <Flex
        sx={{
          display: ['none', null, 'flex'],
          position: 'relative',
          flexDirection: ['row', null, 'column'],
          justifyContent: 'space-between',
          alignItems: 'center',
          width: ['100%', null, 160],
          height: [null, null, '100%'],
          minHeight: [null, null, 264],
          maxHeight: [null, null, 360],
          pt: 2,
          pb: 3,
          px: 2,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: isSelected ? 'primary' : 'border',
          borderRadius: 1,
          color: isSelected ? 'white' : 'inherit',
          backgroundColor: isSelected ? 'primary' : 'white',
          ':hover': {
            borderColor: isSelected ? 'primary' : 'grayLight',
            cursor: 'pointer',
          },
          img: {
            filter: isSelected
              ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)'
              : 'none',
          },
        }}
      >
        {hasSpecialNote && (
          <div
            sx={{
              bg: isCurrentSize ? 'blueLighter' : 'secondary',
              color: isCurrentSize ? 'secondary' : 'white',
              borderRadius: 1,
              py: 1,
              px: 2,
              fontSize: '11px',
              alignSelf: 'start',
              mt: '-4px',
              ml: '-4px',
            }}
          >
            {translate(
              mailboxDelivery
                ? 'buyParcel.services.JPL.title'
                : isCurrentSize
                  ? 'buyParcel.product.currentSize'
                  : includesDeliveryAndPickup
                    ? 'buyParcel.product.includesDeliveryAndPickup'
                    : 'buyParcel.product.includesDelivery'
            )}
          </div>
        )}
        {specialOffer && (
          <div
            sx={{
              bg: specialOffer?.color || 'primary',
              color: 'white',
              borderRadius: 1,
              py: 1,
              px: 2,
              fontSize: '11px',
              alignSelf: 'start',
              mt: hasSpecialNote ? 0 : '-4px',
              ml: '-4px',
            }}
          >
            {specialOffer?.text?.[language || 'fi']}
          </div>
        )}
        {tooltip && (
          <div sx={{ position: 'absolute', top: 2, right: 2 }}>
            <Tooltip placement="auto" tooltip={tooltip}>
              <InfoIcon />
            </Tooltip>
          </div>
        )}
        <Flex
          sx={{
            flex: 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: 96,
              height: 96,
            }}
          >
            <img src={imagePathForProduct(product.sizeCode)} alt={displayName} width="96" height="96" />
          </Flex>
          <Box
            sx={{
              mt: 2,
              fontSize: 2,
              fontWeight: 'medium',
              textAlign: 'center',
              lineHeight: 1.3,
            }}
          >
            {isSeriesProduct ? (
              <>
                {translate('buyParcel.product.seriesShipment')}
                <br />
                {product.quantity} x {displayName}
              </>
            ) : (
              <span>{displayName}</span>
            )}
          </Box>
        </Flex>
        <Box
          sx={{
            my: 2,
            fontSize: isCurrentSize ? 3 : 4,
            fontWeight: 'heading',
            textAlign: 'center',
            color: isSelected ? 'white' : 'primary',
          }}
        >
          {[SizeCode.REPACK, SizeCode.REFILL].includes(sizeCode) ? (
            <>
              <Box
                sx={{
                  fontSize: 1,
                  textAlign: 'center',
                  fontWeight: 'medium',
                  mb: -1,
                }}
              >
                {translate('buyParcel.product.startPrice')}
              </Box>
              {sizeCode == SizeCode.REPACK ? formatPrice(altProduct?.price) : formatPrice(product.price)}
            </>
          ) : isCurrentSize ? (
            translate('buyParcel.product.addServices')
          ) : product.price > 0 ? (
            formatPrice(product.price)
          ) : (
            translate('buyParcel.product.free')
          )}
        </Box>
        {maxSize ? <Box sx={{ fontSize: 1, textAlign: 'center' }}>{maxSize}</Box> : <Box sx={{ fontSize: 1 }}></Box>}
      </Flex>
      {/* mobile */}
      <Flex
        sx={{
          display: ['flex', null, 'none'],
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: [null, null, '100%'],
          minHeight: [null, null, 264],
          maxHeight: [null, null, 360],
          py: '33px',
          px: 2,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: isSelected ? 'primary' : 'border',
          borderRadius: 1,
          color: isSelected ? 'white' : 'inherit',
          backgroundColor: isSelected ? 'primary' : 'white',
          ':hover': {
            borderColor: isSelected ? 'primary' : 'grayLight',
            cursor: 'pointer',
          },
          img: {
            filter: isSelected
              ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)'
              : 'none',
          },
        }}
      >
        {hasSpecialNote && (
          <div
            sx={{
              position: 'absolute',
              bg: isCurrentSize ? 'blueLighter' : 'secondary',
              color: isCurrentSize ? 'secondary' : 'white',
              borderRadius: 1,
              py: 1,
              px: 2,
              fontSize: '11px',
              alignSelf: 'start',
              top: 1,
              left: 1,
            }}
          >
            {translate(
              mailboxDelivery
                ? 'buyParcel.services.JPL.title'
                : isCurrentSize
                  ? 'buyParcel.product.currentSize'
                  : includesDeliveryAndPickup
                    ? 'buyParcel.product.includesDeliveryAndPickup'
                    : 'buyParcel.product.includesDelivery'
            )}
          </div>
        )}
        {specialOffer && (
          <div
            sx={{
              position: 'absolute',
              bg: specialOffer?.color || 'primary',
              color: 'white',
              borderRadius: 1,
              py: 1,
              px: 2,
              fontSize: '11px',
              alignSelf: 'start',
              top: hasSpecialNote ? 3 : 1,
              left: 0,
              left: 1,
            }}
          >
            {specialOffer?.text?.[language || 'fi']}
          </div>
        )}
        {tooltip && (
          <div sx={{ position: 'absolute', top: 2, right: 2 }}>
            <Tooltip placement="auto" tooltip={tooltip}>
              <InfoIcon />
            </Tooltip>
          </div>
        )}

        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: 72,
            height: 72,
          }}
        >
          <img src={imagePathForProduct(product.sizeCode)} alt={displayName} width="72" height="72" />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 3,
            fontSize: 3,
            fontWeight: 'medium',
            textAlign: 'center',
            lineHeight: 1.3,
          }}
        >
          {isSeriesProduct ? (
            <>
              {translate('buyParcel.product.seriesShipment')}
              <br />
              {product.quantity} x {displayName}
            </>
          ) : (
            <span>{displayName}</span>
          )}
          {maxSize ? (
            <Box sx={{ fontSize: 1, textAlign: 'center', fontWeight: 'normal' }}>{maxSize}</Box>
          ) : (
            <Box sx={{ fontSize: 1 }}></Box>
          )}
        </Flex>

        <Box
          sx={{
            my: 2,
            fontSize: 2,
            mr: 2,
            fontWeight: 'heading',
            textAlign: 'center',
            color: isSelected ? 'white' : 'primary',
          }}
        >
          {[SizeCode.REPACK, SizeCode.REFILL].includes(sizeCode) ? (
            <>
              <Box
                sx={{
                  fontSize: 1,
                  textAlign: 'center',
                  fontWeight: 'medium',
                  mb: -1,
                }}
              >
                {translate('buyParcel.product.startPrice')}
              </Box>
              {sizeCode == SizeCode.REPACK ? formatPrice(altProduct?.price) : formatPrice(product.price)}
            </>
          ) : isCurrentSize ? (
            translate('buyParcel.product.addServices')
          ) : product.price > 0 ? (
            formatPrice(product.price)
          ) : (
            translate('buyParcel.product.free')
          )}
        </Box>
      </Flex>
    </>
  );
};

/** @jsx jsx */
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { jsx, Flex, Box, Container } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { Context } from './Context';
import { Parcel } from '../account/ShipmentDetails';
import { Button, FullHeightColumn, Spinner } from '../components';
import combineProps from '../parcelFlow/combineServiceProps';
import ProductCard from '../parcelFlow/ProductCard';
import { useTranslation } from 'react-i18next';
import { ariaLabelForProduct } from '../utils/shipment';

const ProductList = ({ products, productsLoaded, currentSize }) => {
  const { selectProduct, upgradeData } = useContext(Context);
  const { t: translate } = useTranslation();
  const container = useRef();
  const selectedProduct = upgradeData.product || {};
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    (e) => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.product'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex((i) => {
            const newIndex = i > 0 ? i - 1 : products.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex((i) => {
            const newIndex = i < products.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex((i) => {
            const product = products[i];
            if (product) {
              setImmediate(() => {
                selectProduct(product);
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [products, setFocusIndex, selectProduct]
  );

  return (
    <Flex
      ref={container}
      sx={{
        flexWrap: ['nowrap', null, 'wrap'],
        overflowX: ['auto', null, 'visible'],
        mb: 3,
        mx: [-3, null, 0],
        my: 2,
      }}
      role="radiogroup"
      aria-label={translate('buyParcel.product.title')}
      onKeyDown={onKeyDown}
    >
      {products.length === 0 ? 'Tuotteiden lataaminen epäonnistui.' : null}
      {products.map((p, index) => (
        <Box
          key={p.id}
          onClick={() => selectProduct(p)}
          sx={{
            flex: 'none',
            mr: 2,
            mb: 2,
            ':first-of-type': {
              pl: [3, null, 0],
            },
            ':last-of-type': {
              pr: 3,
            },
          }}
          className="product"
          role="radio"
          aria-label={ariaLabelForProduct(p, translate)}
          aria-checked={p.id === selectedProduct.id}
          tabIndex={focusIndex === index ? 0 : -1}
        >
          <ProductCard
            product={p}
            isSelected={p.id === selectedProduct.id}
            isCurrentSize={currentSize === p.sizeCode}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default () => {
  const { t: translate } = useTranslation();
  const { isLoading, products, shipment, origItem, upgradeData, goBack, goNext } = useContext(Context);
  const { shipmentNumber, parcels = [] } = shipment || {};
  const sortedProducts = useMemo(() => {
    if (!products) return products;
    const index = products.findIndex((p) => p.sizeCode === origItem?.product?.sizeCode);
    if (index === -1) return products;
    const currentItem = products[index];
    const copy = [...products];
    copy.splice(index, 1);
    copy.push(currentItem);
    return copy;
  }, [products, origItem]);

  const item = (origItem && combineProps(origItem.product, origItem.services)) || {};

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Themed.h1 sx={{ color: 'secondary', my: 4 }}>{translate('upgrade.size.title')}</Themed.h1>
      {isLoading ? (
        <Box sx={{}}>
          <Spinner />
        </Box>
      ) : !products || products.length === 0 ? (
        <Box sx={{}}>{translate('upgrade.size.notPossible')}</Box>
      ) : (
        <>
          <Container variant="text" sx={{ ml: 0 }}>
            <Box sx={{}}>{translate('upgrade.size.intro', { shipmentNumber })}</Box>
            <div sx={{ my: 2 }}>
              <Parcel productId={origItem.product.id} {...shipment} override={item} {...(parcels[0] || {})} />
            </div>
            <Box>
              {translate('upgrade.size.select')} {translate('upgrade.size.note')}
            </Box>
          </Container>

          <ProductList products={sortedProducts} currentSize={origItem?.product?.sizeCode} />

          <Flex
            sx={{
              flexDirection: ['row-reverse', null, 'row'],
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ alignSelf: ['flex-end', null, 'flex-start'], flex: 'none' }}
              onClick={goNext}
              disabled={!upgradeData.product}
            >
              {translate('upgrade.size.continue')}
            </Button>
          </Flex>
        </>
      )}
    </FullHeightColumn>
  );
};

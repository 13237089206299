/** @jsx jsx */
import { jsx, Box, Label, Flex } from 'theme-ui';
import Tooltip from './Tooltip';
import { InfoIcon } from './Icon';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useMemo } from 'react';

const RadioFieldGroup = ({ children, width, tooltip, ...props }) => {
  const { width: windowWidth } = useWindowDimensions();
  const tooltipPlacement = useMemo(() => (windowWidth < 600 ? 'top' : 'right'), [windowWidth]);

  return (
    <Box sx={{ mb: 3, width }}>
      {props.label && (
        <Flex>
          <Label sx={{ width: 'auto' }} htmlFor={props.name}>
            {props.label}
          </Label>
          {tooltip && (
            <Tooltip
              trigger={['click']}
              interactive={true}
              placement={tooltipPlacement}
              tooltip={
                <div>
                  <p
                    sx={{ color: 'white', a: { color: 'white', textDecoration: 'underline' } }}
                    dangerouslySetInnerHTML={{
                      __html: tooltip,
                    }}
                  />
                </div>
              }
            >
              <InfoIcon sx={{ ml: 3, display: 'inline-block', verticalAlign: 'bottom' }} />
            </Tooltip>
          )}
        </Flex>
      )}

      {children}
      {props.error && <div style={{ color: 'red' }}>{props.error}</div>}
    </Box>
  );
};

export default RadioFieldGroup;

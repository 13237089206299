import { useStaticQuery, graphql } from 'gatsby';

const isMhCom = process.env.GATSBY_SITE_DOMAIN === 'com';

export const useFooterLinks = (isComSe) => {
  const { customer, corporate } = useStaticQuery(graphql`
    query FooterLinks {
      customer: allContentfulLayout(filter: { slug: { in: ["footer", "footeren", "footersv"] } }) {
        nodes {
          node_locale
          contentModules {
            ... on ContentfulLayoutLinkList {
              title
              headline
              links {
                title
                url
                page {
                  ...pageLinkFields
                }
              }
            }
          }
        }
      }
      corporate: allContentfulLayout(
        filter: {
          slug: {
            in: [
              "footer-corporate"
              "footeren-corporate"
              "footersv-corporate"
              "footercom-en"
              "footercom-sv"
              "footercomse-en"
              "footercomse-sv"
            ]
          }
        }
      ) {
        nodes {
          node_locale
          title
          slug
          metadata {
            tags {
              contentful_id
            }
          }
          contentModules {
            ... on ContentfulLayoutLinkList {
              title
              headline
              links {
                title
                url
                page {
                  ...pageLinkFields
                }
              }
            }
          }
        }
      }
    }
  `);

  const corporateLinks =
    corporate?.nodes?.filter((n) => {
      const tags = n?.metadata?.tags?.map((t) => t?.contentful_id) || [];
      const slug = n?.slug;

      if (!isMhCom) return !tags.includes('matkahuoltoCom');

      // matkahuolto.com needs two footers: one for /se pages and one for the rest
      const allowedSlugs = isComSe ? ['footercomse-en', 'footercomse-sv'] : ['footercom-en', 'footercom-se'];

      return allowedSlugs.includes(slug) && tags.includes('matkahuoltoCom');
    }) || [];

  return { customerLinks: customer.nodes, corporateLinks };
};

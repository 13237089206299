import * as auth from '../utils/auth';
import { navigate, prefetchPathname } from 'gatsby';
import * as api from '../utils/api';
import { showNotification, SEVERITY } from './notifications';
import staticPaths from '../pages/localization.json';
import { isBrowser } from '../utils';
import { useSelector } from 'react-redux';
import i18n from '../localization/i18n';

const defaultLocale = 'fi';

const initialState = {
  isLoading: false,
  user: null,
  error: null,
  locale: defaultLocale,
};

const userSelector = (state) => state.session.user;
export const useUser = () => {
  return useSelector(userSelector);
};

const SET_LOADING = 'SET_LOADING';
const SET_SESSION = 'SET_SESSION';
const LOGOUT = 'LOGOUT';
const SET_CARDS = 'SET_CARDS';
const SET_LOCALE = 'SET_LOCALE';
const SET_PHONE_VERIFIED = 'SET_PHONE_VERIFIED';
const UPDATE_USER = 'UPDATE_USER';

export const getLocale = (user) => {
  const locale = user && user.language ? user.language : defaultLocale;
  return ['fi', 'sv', 'en'].includes(locale) ? locale : 'fi';
};

export const loginWithPopup = () => async (dispatch) => {
  const { user } = await auth.login();
  dispatch({
    type: SET_SESSION,
    user,
    locale: getLocale(user),
  });
};

export const navigateIfWhiteListed = (url, hash = '') => {
  const isTest = process.env.GATSBY_ACTIVE_ENV == 'test';
  const allowedHostnames = ['www.matkahuolto.fi', 'liput.matkahuolto.fi'];
  if (!allowedHostnames.includes(url.hostname) && !isTest) {
    console.log(`Invalid redirect URL ${url}: hostname is not whitelisted`, url.hostname);
    navigate('/');
    return;
  } else {
    window.location = url + hash;
  }
};

const navigateWithTokensIfWhiteListed = (navigateTo, passToken = true) => {
  if (!navigateTo.toLowerCase().includes('http')) {
    navigate(navigateTo);
    return;
  }

  const isTest = process.env.GATSBY_ACTIVE_ENV == 'test';
  const url = new URL(navigateTo);
  if (url.protocol !== 'https:' && !isTest) {
    console.log(`Invalid redirect URL ${navigateTo}: https protocol is required`, url.protocol);
    navigate('/');
    return;
  }

  const hash = passToken ? `#token=${encodeURIComponent(api.getToken())}` : '#failed';
  navigateIfWhiteListed(url, hash);
};

const navigateToUserHomePage = (user) => {
  const language = getLocale(user) || 'fi';
  const path = {
    fi: '/omat-sivut/saapuvat-paketit',
    sv: '/egna-sidor/paket-pa-vag',
    en: '/my-pages/incoming-parcels',
  }[language];
  navigate(path);
};

export const loginWithCredentials = (username, password, rememberMe, navigateTo, cb) => async (dispatch) => {
  //TODO: add rememberMe handling
  const { user, error } = await auth.login(username, password, rememberMe);
  const locale = getLocale(user);
  dispatch({
    type: SET_SESSION,
    user,
    error,
    locale,
  });
  //dispatch(setActiveLanguage(locale));
  i18n.changeLanguage(locale);

  if (!error) {
    if (navigateTo) {
      navigateWithTokensIfWhiteListed(navigateTo);
    } else {
      navigateToUserHomePage(user);
    }
  } else {
    const { code: errorCode } = (error.response && error.response.data) || {};
    if (errorCode === 'UserNotConfirmedException') {
      dispatch(showNotification('login.message.emailNotConfirmed'));
      dispatch(locNavigate('/email-validate', `?email=${username}`));
    } else {
      dispatch(showNotification('login.message.loginFailed'));
    }
  }
  if (cb) {
    cb(error);
  }
};

export const loginWithToken =
  (token, rememberMe, redirect = true, navigateTo) =>
  async (dispatch) => {
    const { user, error } = await auth.loginWithToken(token, rememberMe);
    const locale = getLocale(user);
    dispatch({
      type: SET_SESSION,
      user,
      error,
      locale,
    });
    //dispatch(setActiveLanguage(locale));
    i18n.changeLanguage(locale);
    if (error && error.name === 'UserAuthError') {
      if (redirect) {
        dispatch(locNavigate('/request-details'));
        return;
      }
    }
    if (error) {
      dispatch(showNotification('login.message.loginFailed'));
    }
    if (!error) {
      if (navigateTo) {
        navigateWithTokensIfWhiteListed(navigateTo);
      } else {
        navigateToUserHomePage(user);
      }
    }
  };

export const checkRefreshTokenAndRedirect = (navigateTo) => async (dispatch) => {
  let { AccessToken, RefreshToken } = auth.getTokens();
  let success = !!AccessToken;
  if (RefreshToken && AccessToken) {
    const tokens = await api.renewTokens(RefreshToken, AccessToken);
    if (!tokens) {
      success = false;
    }
  }
  navigateWithTokensIfWhiteListed(navigateTo, success);
};

export const silentAuth = (callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    isLoading: true,
  });
  const { user } = await auth.silentAuth();
  if (user) {
    // fully change user locale on silent auth
    dispatch(changeLocale(getLocale(user)));
    dispatch({
      type: SET_SESSION,
      user,
      locale: getLocale(user),
    });
  } else {
    dispatch({
      type: SET_LOADING,
      isLoading: false,
    });
  } /**else if (auth.getToken()!=null) {
    dispatch({
      type: LOGOUT,
    });
  }*/
  if (callback) callback();
};

export const logout = () => async (dispatch) => {
  auth.logout();
  const params = new URLSearchParams({
    client_id: process.env.AUTH_APP_CLIENT_ID,
    logout_uri: process.env.GATSBY_WEB_URL,
  }).toString();
  setTimeout(() => window.location.replace(`https://${process.env.AUTH_USER_POOL}/logout?${params}`), 1); // cookie removal require some time
};

export const updateUser = (user) => async (dispatch) => {
  try {
    const updatedUser = await api.updateProfile(user);
    const locale = getLocale(updatedUser);
    dispatch({
      type: SET_SESSION,
      user: updatedUser,
      error: null,
      locale,
    });
    i18n.changeLanguage(locale);
    dispatch(showNotification('updateSuccess', SEVERITY.INFO));
  } catch (error) {
    console.warn('Failed to update user:' + user);
    dispatch(showNotification('updateFailed'));
  }
};

export const removeProfile = () => async (dispatch) => {
  try {
    await api.removeProfile();
    dispatch(logout());
    dispatch(showNotification('removeProfileSuccess', SEVERITY.INFO));
  } catch (error) {
    console.warn("Failed to remove user's profile", error);
    dispatch(showNotification('removeProfileFailed'));
  }
};

export const getCards = () => async (dispatch) => {
  try {
    const cards = await api.getCards();
    dispatch({
      type: SET_CARDS,
      cards,
    });
  } catch (error) {
    console.warn('Failed to get cards for user');
  }
};

export const setPhoneVefified = () => {
  return {
    type: SET_PHONE_VERIFIED,
  };
};

export const removeCard = (id) => async (dispatch) => {
  try {
    await api.removeCard(id);
    dispatch(getCards());
    dispatch(showNotification('removeCardSuccess', SEVERITY.INFO));
  } catch (error) {
    console.warn("Failed to remove user's card: " + id, error);
    dispatch(showNotification('removeCardFailed'));
  }
};

export const changeLocale = (locale, paths) => async (dispatch) => {
  let loc = 'fi';
  if (locale && 'sv' === locale.toLowerCase()) loc = 'sv';
  else if (locale && 'en' === locale.toLowerCase()) loc = 'en';
  dispatch({
    type: SET_LOCALE,
    locale: loc,
  });

  i18n.changeLanguage(loc);
  if (paths && paths[locale]) {
    console.log('Navigate to: ' + paths[locale]);
    navigate(paths[locale] + location.search + location.hash, { replace: true });
  }
};

function findLocPaths(staticPath) {
  let locPaths = staticPaths[staticPath.substring(1)]; // find by english
  if (locPaths) {
    return [locPaths, staticPath];
  }
  for (const keyPath in staticPaths) {
    const locales = staticPaths[keyPath];
    for (const locale in locales) {
      const localePath = staticPaths[keyPath][locale];
      if (localePath === staticPath) {
        return [locales, '/' + keyPath];
      }
    }
  }
  return [];
}

export const prefetchPage = (staticPath) => (dispatch, getState) => {
  if (!isBrowser) {
    return;
  }
  const userLocale = i18n.language;
  let [locPaths, enPath] = findLocPaths(staticPath);
  if (locPaths && enPath) {
    if (userLocale === 'en') {
      return prefetchPathname(enPath);
    } else if (locPaths[userLocale]) {
      return prefetchPathname(locPaths[userLocale]);
    }
  }
  return prefetchPathname(staticPath);
};

export const locNavigate =
  (staticPath, postfix = '', options) =>
  (dispatch, getState) => {
    const userLocale = i18n.language;
    // console.log('StaticPaths'+JSON.stringify(staticPaths));
    let [locPaths, enPath] = findLocPaths(staticPath);
    if (locPaths && enPath) {
      if (userLocale === 'en') {
        // console.log('en navigate to: '+enPath);
        navigate(enPath + postfix, options);
        return;
      }
      if (locPaths[userLocale]) {
        // console.log(userLocale+' navigate to: '+locPaths[userLocale]);
        navigate(locPaths[userLocale] + postfix, options);
        return;
      }
    }
    // fallback to default gatsby navigations
    // console.log('Fallback and navigate to: '+staticPath);
    navigate(staticPath + postfix, options);
  };

export const updateReceiptHero = (isLinked) => {
  return {
    type: UPDATE_USER,
    user: { receiptHero: isLinked },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_SESSION:
      return { user: action.user, error: action.error, locale: action.locale, isLoading: false };
    case SET_CARDS:
      return { user: { ...state.user, cards: action.cards }, error: null, locale: state.locale };
    case SET_LOCALE:
      return { user: state.user, error: state.error, locale: action.locale };
    case SET_PHONE_VERIFIED:
      return { ...state, user: { ...state.user, phoneVerified: true } };
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.user } };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

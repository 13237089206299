/** @jsx jsx */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { jsx, Box } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { FullHeightColumn, Link } from '../components';
import { reloadShipments } from '../state/shipments';
import { useTranslation } from 'react-i18next';

export default ({ order }) => {
  const dispatch = useDispatch();
  const { shipmentNumber, product = {}, services } = order?.shipments?.[0] || {};
  const { t: translate } = useTranslation();
  useEffect(() => {
    dispatch(reloadShipments());
  }, [dispatch]);

  return (
    <FullHeightColumn>
      <Themed.h1 sx={{ color: 'primary', my: 4 }}>{translate('upgrade.ready.title')}</Themed.h1>
      <Box>
        {translate('upgrade.ready.description1')}{' '}
        <Link to={`/my-pages/sent-parcels?sn=${shipmentNumber}`}>{shipmentNumber}</Link>{' '}
        {translate('upgrade.ready.description2', {
          newSize: product.sizeCode + (product.weightClass > 30 ? ` ${product.weightClass} kg` : ''),
        })}
      </Box>
    </FullHeightColumn>
  );
};

/** @jsx jsx */
import { Flex, jsx, useThemeUI } from 'theme-ui';

import Button from './Button';

const buttonStyle = {
  padding: '0',
  borderRadius: '50%',
  height: '0.8em',
  width: '0.8em',
  lineHeight: '0',
  fontSize: '2.6em',
  margin: '0.2em',
};

const IntegerField = ({ field, form, step, innerRef, onChange, ...props }) => {
  const { theme } = useThemeUI();
  const increment = e => {
    e.preventDefault();
    let newVal = (field.value || 0) + step;
    newVal = Math.floor(newVal / step) * step; // round number
    form.setFieldValue(field.name, newVal);
    onChange && onChange(newVal);
  };

  const decrement = e => {
    e.preventDefault();
    let newVal = (field.value || 0) - step;
    newVal = Math.ceil(newVal / step) * step; // round number
    form.setFieldValue(field.name, newVal < 1 ? 1 : newVal);
    onChange && onChange(newVal);
  };

  const decrementDisabled = field.value <= 1;

  return (
    <Flex sx={{ width: 'max-content' }}>
      <input {...field} {...props} />
      <div>
        <Button
          sx={{
            ...buttonStyle,
            paddingBottom: '0.1em',
            background: decrementDisabled ? theme.colors.grayLight : theme.colors.primary,
          }}
          disabled={decrementDisabled}
          onClick={decrement}
        >
          –
        </Button>
      </div>
      <div>
        <Button sx={buttonStyle} onClick={increment}>
          +
        </Button>
      </div>
    </Flex>
  );
};

export default IntegerField;

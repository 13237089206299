/** @jsx jsx */
import { jsx, Flex, Box, Checkbox, Label } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import FullHeightColumn from './FullHeightColumn';
import { getCards } from '../state/session';

const paytrailMethods = {
  PaytrailOP: { name: 'Osuuspankki', icon: '/payment/op.svg' },
  PaytrailNordea: { name: 'Nordea', icon: '/payment/nordea.svg' },
  PaytrailDanskeBank: { name: 'Danske Bank', icon: '/payment/danske.svg' },
  PaytrailSaastopankki: {
    name: 'Säästöpankki',
    icon: '/payment/saastopankki.svg',
  },
  PaytrailSPankki: { name: 'S-Pankki', icon: '/payment/s-pankki.svg' },
  PaytrailPOP: { name: 'POP-Pankki', icon: '/payment/pop-pankki.svg' },
  PaytrailAktia: { name: 'Aktia', icon: '/payment/aktia.svg' },
  PaytrailHandelsbanken: {
    name: 'Handelsbanken',
    icon: '/payment/handelsbanken.svg',
  },
  PaytrailAlandsbanken: {
    name: 'Ålandsbanken',
    icon: '/payment/alandsbanken.svg',
  },
  PaytrailOmaSaastopankki: {
    name: 'Oma Säästöpankki',
    icon: '/payment/omasp.svg',
  },
};

const PaymentButton = ({ image, alt, text, onClick }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        width: ['50%', '33.333%', '25%', '20%'],
        px: 1,
        pb: 2,
      }}
    >
      <button
        aria-label={alt || text || 'Payment button'}
        sx={{
          width: '100%',
          height: 80,
          bg: 'white',
          position: 'relative',
          borderStyle: 'solid',
          borderColor: 'border',
          borderWidth: 2,
          borderRadius: 2,
          ':hover': {
            borderColor: 'grayLight',
            cursor: 'pointer',
          },
          ':focus:not(:active)': {
            boxShadow: 'inputFocus',
          },
          img: {
            maxWidth: '180px',
            maxHeight: '70px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
        onClick={onClick}
      >
        <img src={image} alt={alt} />
      </button>
      {text && <span sx={{ mt: 1 }}>{text}</span>}
    </Flex>
  );
};

const PaymentTitle = ({ children }) => {
  return <Themed.h3 as="h2">{children}</Themed.h3>;
};

export const PaymentSelection = ({ onPayButton, onBackClick }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const user = useSelector((state) => state.session.user);
  const [saveCard, setSaveCard] = useState(false);
  const toggleSaveCard = useCallback(() => {
    setSaveCard((oldValue) => !oldValue);
  }, [setSaveCard]);

  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  return (
    <FullHeightColumn>
      {onBackClick && (
        <Box>
          <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
            {translate('backButton')}
          </Button>
        </Box>
      )}

      <Themed.h1 sx={{ color: 'secondary', mb: 1 }}>{translate('buyParcel.payment.select')}</Themed.h1>
      <PaymentTitle>{translate('buyParcel.payment.directDebitButton')}</PaymentTitle>
      <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap', mx: -1 }}>
        {Object.entries(paytrailMethods).map(([k, v]) => (
          <PaymentButton key={k} image={v.icon} alt={v.name} onClick={() => onPayButton({ paymentMethod: k })} />
        ))}
      </Flex>
      <PaymentTitle>{translate('buyParcel.payment.creditCardButton')}</PaymentTitle>
      <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap', mx: -1 }}>
        {user &&
          user.cards &&
          user.cards.map((card) => (
            <PaymentButton
              key={card.id}
              image={['MC', 'MasterCard'].includes(card.cardType) ? '/payment/mastercard.svg' : '/payment/visa.svg'}
              alt={card.cardType}
              text={card.maskedCardNumber}
              onClick={() =>
                onPayButton({
                  paymentMethod: 'SAVED_CARD',
                  agreementNumber: card.agreementNumber,
                })
              }
            />
          ))}
        <PaymentButton
          image="/payment/visa.svg"
          alt="Visa"
          onClick={() => onPayButton({ paymentMethod: 'nets-creditcard', saveCard })}
        />
        <PaymentButton
          image="/payment/mastercard.svg"
          alt="MasterCard"
          onClick={() => onPayButton({ paymentMethod: 'nets-creditcard', saveCard })}
        />
      </Flex>
      {user && (
        <Label sx={{ mt: 2 }}>
          <Checkbox checked={saveCard} onChange={toggleSaveCard} />
          <span sx={{ mb: 2 }}>{translate('buyParcel.payment.saveCreditCard')}</span>
        </Label>
      )}
      <PaymentTitle>{translate('buyParcel.payment.mobilePayButton')}</PaymentTitle>
      <Flex sx={{ mx: -1 }}>
        <PaymentButton
          image="/payment/mobilepay.svg"
          alt="MobilePay"
          onClick={() => onPayButton({ paymentMethod: 'nets-mobilepay' })}
        />
      </Flex>

      <div sx={{ flex: 'none', mt: 4 }}>
        <Themed.p>{translate('buyParcel.payment.netsNote')}</Themed.p>
      </div>
    </FullHeightColumn>
  );
};

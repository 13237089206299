/** @jsx jsx */
import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { jsx, Flex, Box, Input as DummyInput } from 'theme-ui';
import { InstantSearch, connectStateResults, connectHits } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import Input from './input';
import * as hitComps from './hitComps';
import TrackingQuestion from './tracking';
import { SearchStore } from './SearchContext';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '../Icon';
import { useKeyboardNavigation } from './keyboardHandler';
import { useColors } from '../../hooks/useColors';

const { PageHit, FAQHit, NewsHit, AccordionHit } = hitComps;

const Results = connectStateResults(({ searchState: state, searchResults: res, children }) => {
  const { t: translate } = useTranslation();

  return res && res.nbHits > 0 ? (
    children
  ) : (
    <Box
      sx={{ textAlign: 'center', my: 3 }}
      dangerouslySetInnerHTML={{
        __html: translate('search.noResults', { query: state.query }),
      }}
    />
  );
});

const Stats = ({ count }) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      {translate('search.results')} ({count})
    </div>
  );
};

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY, {
  // algolia duplicate search bug, see https://github.com/algolia/react-instantsearch/issues/355
  _useRequestCache: true,
});

const useLocale = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  if (locale === 'sv') {
    return 'sv-SE';
  } else if (locale === 'en') {
    return 'en-US';
  }
  return 'fi-FI';
};

const CustomHits = connectHits(({ hits, clickHandler, edges }) => {
  const { t: translate } = useTranslation();
  const locale = useLocale();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const faqHits = [];
  const blogHits = [];
  const otherHits = [];
  const accordionHits = [];
  hits.forEach((h) => {
    if (h.type === 'frequentlyAskedQuestion') {
      faqHits.push(h);
    } else if (h.type === 'blogPost') {
      blogHits.push(h);
    } else if (h.type === 'accordion') {
      if (h?.searchTarget && h?.searchTarget[locale]) accordionHits.push(h);
    } else if (h.type === 'page' || h.type === 'layout') {
      const hideLocales = Object.values(h?.hideLocales || {})?.flat() || [];
      if (!hideLocales.includes(lang)) {
        otherHits.push(h);
      }
    } else {
      otherHits.push(h);
    }
  });

  return (
    <div>
      {otherHits.length + accordionHits.length > 0 ? (
        <Box>
          <Stats count={otherHits.length + accordionHits.length} />
        </Box>
      ) : null}

      {otherHits.length > 0 && (
        <>
          {otherHits.map((hit) => (
            <PageHit key={hit.objectID} hit={hit} clickHandler={clickHandler} edges={edges} />
          ))}
        </>
      )}

      {accordionHits.length > 0 && (
        <>
          {accordionHits.map((hit) => (
            <AccordionHit key={hit.objectID} hit={hit} clickHandler={clickHandler} edges={edges} />
          ))}
        </>
      )}

      {blogHits.length > 0 && (
        <>
          <div
            sx={{
              mt: 24,
              mb: 2,
              pt: otherHits.length > 0 ? 24 : 1,
              borderTopStyle: 'solid',
              borderTopWidth: otherHits.length > 0 ? 1 : 0,
              borderTopColor: 'border',
            }}
          >
            {translate('search.newsAndBlog')} ({blogHits.length})
          </div>
          {blogHits.map((hit) => (
            <NewsHit key={hit.objectID} hit={hit} clickHandler={clickHandler} edges={edges} />
          ))}
        </>
      )}

      {faqHits.length > 0 && (
        <>
          <div
            sx={{
              mt: 24,
              mb: 2,
              pt: otherHits.length > 0 ? 24 : 1,
              borderTopStyle: 'solid',
              borderTopWidth: otherHits.length > 0 ? 1 : 0,
              borderTopColor: 'border',
            }}
          >
            {translate('search.faq')}
          </div>
          {faqHits.map((hit) => (
            <FAQHit key={hit.objectID} hit={hit} clickHandler={clickHandler} edges={edges} />
          ))}
        </>
      )}
    </div>
  );
});

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = (event) => {
    ref.current && !ref.current.contains(event.target) && handler();
  };
  useEffect(() => {
    for (const event of events) document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events) document.removeEventListener(event, detectClickOutside);
    };
  });
};

const SearchAlgoliaComponent = ({ indices, collapse = true, hitsAsGrid, edges, darkMode }) => {
  const ref = createRef();
  const inputRef = useRef();
  const [query, setQuery] = useState('');
  const [focus, setFocus] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const { hiContrast, lowContrast } = useColors(darkMode);
  const { t: translate } = useTranslation();

  const triggerChange = (e) => {
    const val = e.target.value;
    // do not search when there is not enough characters
    setQuery(val);
    setSearchStatus(val.length >= 3);
  };

  const focusOnInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setFocus(true);
  }, [inputRef]);
  useClickOutside(ref, () => setFocus(false));

  const clickHandler = useCallback(() => setFocus(false), [setFocus]);

  useKeyboardNavigation(ref, []);

  return (
    <Flex
      role="search"
      sx={{
        position: 'relative',
        zIndex: 200,
        justifyContent: 'space-between',
        width: ['100%', null, null, 'auto'],
        py: 2,
        px: [2, null, 3],
        mx: [0, null, null, 3],
        flex: ['none', null, null, 1],
        borderRadius: 1,
        alignItems: 'center',
        color: focus ? (darkMode ? lowContrast : hiContrast) : hiContrast,
        bg: focus
          ? ['white', null, darkMode ? 'white' : 'grayLighter']
          : ['white', null, darkMode ? 'rgba(0,0,0,.2)' : 'grayLighter'],
        cursor: ['default', null, 'pointer'],
      }}
      ref={ref}
    >
      <SearchStore>
        <Flex onClick={focusOnInput} sx={{ alignItems: 'center', width: '100%' }}>
          <SearchIcon sx={{ flex: 'none', mr: 2 }} aria-hidden="true" />
          <DummyInput
            className="searchInput"
            onFocus={() => setFocus(true)}
            type="search"
            placeholder={translate('search.title')}
            aria-label={translate('search.title')}
            onChange={triggerChange}
            ref={inputRef}
            sx={{
              width: '100%',
              color: 'inherit',
              bg: 'transparent',
              border: 'none',
              p: 0,
              ':hover, :focus, :hover:focus': {
                boxShadow: 'none',
                border: 'none',
              },
              '::-webkit-input-placeholder': {
                color: hiContrast,
              },
              '::-moz-placeholder': {
                color: hiContrast,
              },
              ':-ms-input-placeholder': {
                color: hiContrast,
              },
              '::placeholder': {
                color: hiContrast,
              },
            }}
          />
        </Flex>
        {searchStatus === true && (
          <InstantSearch searchClient={searchClient} indexName={indices[0].name}>
            <Input value={query} />
            <TrackingQuestion setSearchStatus={setSearchStatus} />
            <Box
              show={query.length > 0 && focus}
              asGrid={hitsAsGrid}
              sx={{
                display: query.length >= 3 && focus ? `block` : `none`,
                position: 'absolute',
                top: 'calc(100% + 0.5em)',
                right: 0,
                left: 0,
                zIndex: 2,
                maxHeight: '80vh',
                p: [3, null, 24],
                overflowY: 'scroll',
                overflowX: 'hidden',
                bg: 'white',
                borderRadius: 1,
                boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(0,0,0,.08)',
                '-webkit-overflow-scrolling': 'touch',
                mark: {
                  bg: 'blueLighter',
                  color: 'primary',
                },
                ul: {
                  m: 0,
                  p: 0,
                  listStyle: 'none',
                },
              }}
            >
              <Results>
                <CustomHits clickHandler={clickHandler} edges={edges} />
              </Results>
            </Box>
          </InstantSearch>
        )}
      </SearchStore>
    </Flex>
  );
};

export default SearchAlgoliaComponent;

/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { useState, useCallback, Component, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import Button from '../Button';
import { DateInput } from '../DateField';
import { autocompleteBusLocation } from '../../utils/api';
import { formatDate } from '../../utils/formatters';

import Spinner from '../Spinner';
import { CalendarIcon, CaretIcon } from '../Icon';
import moment from 'moment';

// this is react component instead of function because annoying warning message in browser console caused by <DateInput customInput=... />
class DateButton extends Component {
  render() {
    const { value, onClick, translate } = this.props;
    return (
      <Button
        variant="plain"
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          color: 'white',
          borderRadius: 0,
          ':hover': { color: 'white' },
        }}
        onClick={onClick}
      >
        <CalendarIcon sx={{ mr: 2, flex: 'none' }} />
        <Box>{value ? value : moment().format('DD.MM.YYYY')}</Box>
        <CaretIcon sx={{ flex: 'none', ml: 1 }} />
      </Button>
    );
  }
}

export default ({ sxx }) => {
  const { t: translate, i18n } = useTranslation();
  const language = i18n.language;
  const { theme } = useThemeUI();

  const [departurePlaceId, setDepartureId] = useState();
  const selectDeparturePlace = useCallback(
    (v) => {
      if (!v) {
        return;
      }
      setDepartureId(v.id);
      destinationRef.current.focus();
      return `${v.name} (${v.region})`;
    },
    [setDepartureId]
  );

  const [arrivalPlaceId, setArrivalId] = useState();
  const selectArrivalPlace = useCallback(
    (v) => {
      if (!v) {
        return;
      }
      setArrivalId(v.id);
      return `${v.name} (${v.region})`;
    },
    [setArrivalId]
  );

  const [departureDate, setDepartureDate] = useState();
  const selectDate = useCallback(
    (v) => {
      setDepartureDate(v);
    },
    [setDepartureDate]
  );

  const redirectToTicketService = useCallback(() => {
    let url = process.env.GATSBY_TICKET_API_URL + '/timetable?';
    url += `lang=${language}`;
    url += `&departurePlaceId=${departurePlaceId || ''}`;
    url += `&arrivalPlaceId=${arrivalPlaceId || ''}`;
    url += `&departureDate=${formatDate(departureDate)}`;
    // url += `&returnDate=${returnDate || ''}`;
    window.location.assign(url);
  });

  const selectStyles = {
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base) => ({
      ...base,
      position: 'static',
      borderRadius: 1,
      padding: 6,
      boxShadow: 'none',
    }),
    indicatorsContainer: (base) => ({ display: 'none' }),
    singleValue: (base, { selectProps: { alignRight } }) => ({
      ...base,
      right: alignRight ? '0' : 'none',
    }),
    placeholder: (base, { selectProps: { alignRight } }) => ({
      ...base,
      right: alignRight ? theme.space[2] : 'none',
      color: theme.colors.gray,
    }),
    menu: (base) => ({
      ...base,
      left: 0,
      paddingTop: 8,
      paddingBottom: 8,
    }),
    option: (base, state) => ({
      ...base,
      color: '#001E60',
      backgroundColor: state.isFocused ? '#E5E8EF' : 'none',
      ':hover': { backgroundColor: '#E5E8EF' },
    }),
  };

  const destinationRef = useRef(null);

  return (
    <Flex
      sx={{
        width: '100%',
        bg: 'primary',
        px: [4, null, null, 50],
        pt: [4, null, null, 34],
        pb: [4, null, null, 50],
        flexDirection: ['column', null, null, 'row'],
        gap: 3,
        ...sxx,
      }}
    >
      <Flex
        sx={{
          flex: 3,
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Themed.h3 sx={{ color: 'white', m: 0 }}>{translate('timeTableWidget.title')}</Themed.h3>

          <Box sx={{ mt: [null, null, null, '-10px'] }}>
            <DateInput
              newStyle={false}
              selected={departureDate}
              onChange={selectDate}
              disabledKeyboardNavigation
              dateFormat="dd.MM.yyyy"
              customInput={<DateButton translate={translate} />}
            />
          </Box>
        </Flex>
        <Flex
          sx={{
            flex: 2,
            position: 'relative',
            flexDirection: ['column', null, null, 'row'],
            borderRadius: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.colors.gray,
            gap: 3,
          }}
        >
          <AsyncSelect
            aria-label={translate('timeTableWidget.from')}
            placeholder={translate('timeTableWidget.from')}
            styles={selectStyles}
            isClearable={true}
            loadOptions={(term) => autocompleteBusLocation(term, language)}
            getOptionLabel={(opt) => {
              return `${opt.name} (${opt.region})`;
            }}
            isOptionSelected={(option) => departurePlaceId === option.id}
            onChange={selectDeparturePlace}
            noOptionsMessage={() => null}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />

          <AsyncSelect
            aria-label={translate('timeTableWidget.to')}
            placeholder={translate('timeTableWidget.to')}
            ref={destinationRef}
            styles={selectStyles}
            loadOptions={(term) => autocompleteBusLocation(term, language)}
            getOptionLabel={(opt) => {
              return `${opt.name} (${opt.region})`;
            }}
            isOptionSelected={(option) => departurePlaceId === option.id}
            onChange={selectArrivalPlace}
            noOptionsMessage={() => null}
            alignRight={false}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />
        </Flex>
      </Flex>
      <Flex
        sx={{
          flex: 1,
          mt: [3, null, null, 0],
          width: '100%',
          flexDirection: 'row',
          justifyContent: ['flex-start', null, null, 'flex-end'],
          alignItems: ['flex-start', null, null, 'flex-end'],
        }}
      >
        <Button
          variant="secondary"
          onClick={redirectToTicketService}
          sx={{
            bg: 'white',
            color: 'primary',
            px: [4, 4, 4, 4, 50],
          }}
        >
          {translate('timeTableWidget.search')}
        </Button>
      </Flex>
    </Flex>
  );
};

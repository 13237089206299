/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jsx, Flex, Box, Input } from 'theme-ui';
import Link from '../../components/Link';
import { locNavigate } from '../../state/session';
import { findTrackingInfo } from '../../state/tracking';
import { Button } from '..';
import Widget from './Widget';
import { hideAllNotifications } from '../../state/notifications';
import Spinner from '../Spinner';
import VisuallyHidden from '../VisuallyHidden';
import { ArrowIcon } from '../Icon';

export default function TrackingSerachWidget(props) {
  const [input, setInput] = useState(props.input || '');
  const { t: translate } = useTranslation();
  const isFetching = useSelector((state) => state.tracking.fetching);
  const dispatch = useDispatch();

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(hideAllNotifications());
      dispatch(findTrackingInfo(input, false));
    },
    [dispatch, input]
  );

  function onInputChange(e) {
    const { value } = e.target;
    setInput(value.replace(/[^A-z0-9]/g, ''));
  }

  const titleColor = props?.frontPage ? 'primary' : undefined;
  const bgColor = props?.frontPage ? '#F9B8CB' : undefined;
  const color = props?.frontPage ? 'black' : undefined;

  return (
    <Widget
      title={translate('tracking.search.title')}
      titleColor={titleColor || 'pinkDark'}
      bg={bgColor}
      color={color}
      {...props}
    >
      <Box sx={{ fontSize: [2, 18] }}>{translate('tracking.search.description')}</Box>
      <form onSubmit={onSearch}>
        <Flex
          sx={{
            bg: 'white',
            borderRadius: 1,
            height: 48,
            pl: 2,
            mt: 3,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <VisuallyHidden>
            <label htmlFor="tracking-search">{translate('tracking.search.label')}</label>
          </VisuallyHidden>
          <Input
            placeholder={translate('tracking.search.placeholder')}
            value={input}
            onChange={onInputChange}
            id="tracking-search"
            sx={{
              border: 0,
              width: '100%',
              ':hover, :focus, :focus:hover': {
                boxShadow: 'none',
                border: 0,
              },
            }}
          />
          {isFetching && (
            <div sx={{ position: 'relative', right: '24px' }}>
              <Spinner size="medium" />
            </div>
          )}
          <Button
            variant="plain"
            as={Link}
            to={'/seuranta?parcelNumber=' + input}
            onClick={onSearch}
            sx={{ flex: 'none', whiteSpace: 'nowrap', color: 'gray', px: 3 }}
          >
            <VisuallyHidden>{translate('tracking.search.button')}</VisuallyHidden>
            <ArrowIcon />
          </Button>
        </Flex>
      </form>
    </Widget>
  );
}

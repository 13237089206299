/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui';
import VisuallyHidden from './VisuallyHidden';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useColors } from '../hooks/useColors';

const NavBar = (props) => (
  <Box
    sx={{
      position: 'absolute',
      top: '12px',
      height: 2,
      bg: props.close ? 'currentColor' : 'white',
      width: '100%',
      borderRadius: 2,
      transition: 'all .25s ease',

      '&:nth-of-type(2)': {
        transform: props.close ? 'rotate(0deg)' : 'rotate(45deg)',
      },
      '&:nth-of-type(3)': {
        transform: props.close ? 'rotate(0deg)' : 'rotate(-45deg)',
      },
      '&:first-of-type': {
        top: props.close ? '5px' : '12px',
        width: props.close ? '100%' : 0,
        left: props.close ? 0 : '50%',
      },
      '&:last-of-type': {
        top: props.close ? 19 : '12px',
        width: props.close ? '100%' : 0,
        left: props.close ? 0 : '50%',
      },
    }}
  />
);

const NavigationToggle = (props) => {
  const { t: translate } = useTranslation();
  const { hiContrast } = useColors(props?.darkMode);
  const color = props?.color || hiContrast;

  return (
    <Button
      variant="plain"
      {...props}
      sx={{
        display: ['flex', null, null, 'none'],
        width: 48,
        height: 48,
        px: 0,
        mx: -2,
        justifyContent: 'center',
        bg: 'transparent',
        color,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          width: 24,
          height: 24,
          transition: '.4 ease-in-out',
        }}
      >
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
      </Box>
      <VisuallyHidden>{translate('navigation.menu')}</VisuallyHidden>
    </Button>
  );
};

export default NavigationToggle;

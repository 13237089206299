/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PageGallery = ({ title, images }) => (
  <Grid
    sx={{
      gridTemplateColumns: ['1fr', null, '1fr 1fr'],
      gridGap: [3, null, null, 4],
      mx: [-3, null, null, -4, -6],
      my: [4, null, null, 5],
    }}
  >
    {images &&
      images.map((image) => (
        <GatsbyImage
          image={getImage(image)}
          alt={image?.description || ''}
          key={image.contentful_id}
          sx={{
            width: '100%',
            borderRadius: [0, 0, 16],
            bg: 'secondary',
          }}
        />
      ))}
  </Grid>
);

export default PageGallery;

/** @jsx jsx */
import { jsx, Flex, Box, Label } from 'theme-ui';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, useFormikContext } from 'formik';
import FormField from '../components/FormField';
import Button from '../components/Button';
import AutocompleteStreetField from '../components/AutocompleteStreetField';
import PostalCodeAndCityFields from '../components/PostalCodeAndCityFields';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { getLocalizedCountryName } from '../utils/countryCode';

import * as phoneNumber from '../utils/phoneNumber';

export default ({
  requireAddress,
  eitherPhoneOrEmail,
  toAbroad,
  askRemarks,
  askUnattended,
  country,
  askVATNumber,
  buttonText,
  handleSubmit,
  isSubmitting,
}) => {
  const { errors, values } = useFormikContext();
  const { t: translate, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <Form
      sx={{
        flex: 1,
        width: '100%',
        maxWidth: 640,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <FormField name="name" label={translate('buyParcel.recipient.name')} />
        <Flex
          sx={{
            flexDirection: requireAddress ? 'row' : 'column',
            flexWrap: 'wrap',
            mx: -2,
          }}
        >
          <Box sx={{ width: requireAddress ? ['100%', '50%'] : '100%', px: 2 }}>
            <FormField
              name="phone"
              label={translate('buyParcel.recipient.phone') + (eitherPhoneOrEmail ? '' : ' *')}
              placeholder={phoneNumber.placeHolderFor(country)}
            />
          </Box>
          <Box sx={{ width: requireAddress ? ['100%', '50%'] : '100%', px: 2 }}>
            <FormField name="email" label={translate('buyParcel.recipient.email') + (country !== 'FI' ? ' *' : '')} />
          </Box>
        </Flex>
        {askVATNumber && (
          <div>
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ minWidth: '50%' }}>
                <FormField name="vatNumber" label={translate('buyParcel.recipient.vatNumber')} />
              </Box>
              <span sx={{ ml: 3, pt: 3 }}>{translate('buyParcel.recipient.vatRek')}</span>
            </Flex>
            <div sx={{ color: 'gray', fontSize: 'small' }}>{translate('buyParcel.recipient.vatHelp')}</div>
          </div>
        )}
        {requireAddress ? (
          <>
            <Flex sx={{ flexWrap: 'wrap', mx: -2 }}>
              <div sx={{ flexGrow: 1, width: ['100%', 'auto'], px: 2, zIndex: 3 }}>
                <AutocompleteStreetField label={translate('buyParcel.recipient.street')} country={country} />
              </div>
              <div sx={{ minWidth: ['60%', '33%'], px: 2 }}>
                <FormField name="apartment" label={translate('address.apartment')} />
              </div>
            </Flex>
            <PostalCodeAndCityFields country={country} />
            {toAbroad && (
              <>
                <Box sx={{ mb: 3 }}>
                  <Label>{translate('buyParcel.recipient.country')}</Label>
                  <div>{getLocalizedCountryName(country, language)}</div>
                </Box>
              </>
            )}
            {askRemarks && (
              <FormField name="remarks" label={translate('buyParcel.recipient.remarks' + (toAbroad ? 'Abroad' : ''))} />
            )}
            {askUnattended && (
              <RadioFieldGroup
                id="unattendedHandover"
                error={errors.unattendedHandover}
                label={translate('buyParcel.recipient.unattended')}
              >
                <Field
                  component={RadioField}
                  name="unattendedHandover"
                  id="yes"
                  label={translate('buyParcel.recipient.unattendedYes')}
                />
                <Field
                  component={RadioField}
                  name="unattendedHandover"
                  id="no"
                  label={translate('buyParcel.recipient.unattendedNo')}
                />
              </RadioFieldGroup>
            )}
          </>
        ) : null}
      </Box>
      <Flex
        sx={{
          flex: 'none',
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {buttonText || translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Hero from './Hero';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Carousel = ({ theme, slides, ...props }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const shownSlides = useMemo(() => {
    return (slides || []).filter((s) => s?.languages?.includes(locale) || !!!s?.languages);
  }, [slides, locale]);

  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={300}
      totalSlides={shownSlides.length}
      isIntrinsicHeight
      infinite
      isPlaying
      lockOnWindowScroll
      sx={{
        position: 'relative',
      }}
      {...props}
    >
      <Slider>
        {shownSlides.map((slide) => (
          <Slide key={slide.id}>
            <Hero
              style={slide.visualStyle}
              backgroundImage={slide.backgroundImage}
              title={slide.headline}
              cta={slide.callToAction}
              backgroundColor={slide.backgroundColor}
              accentColor={slide.accentColor}
              theme={theme}
            />
          </Slide>
        ))}
      </Slider>
      <ButtonBack
        sx={{
          variant: 'buttons.plain',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 48,
          height: '100%',
          fontSize: 4,
          color: 'rgba(255,255,255,0)',
          ':hover': {
            color: 'rgba(255,255,255,1)',
          },
        }}
      >
        &larr;
      </ButtonBack>
      <ButtonNext
        sx={{
          variant: 'buttons.plain',
          position: 'absolute',
          top: 0,
          right: 0,
          width: 48,
          height: '100%',
          fontSize: 4,
          color: 'rgba(255,255,255,0)',
          ':hover': {
            color: 'rgba(255,255,255,1)',
          },
        }}
      >
        &rarr;
      </ButtonNext>
      <DotGroup
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          '.carousel__dot': {
            width: 100 / shownSlides.length + '%',
            height: 6,
            p: 0,
            m: 0,
            bg: 'rgba(240,240,240,.1)',
            border: 0,
          },
          '.carousel__dot--selected': {
            bg: 'rgba(240,240,240,.25)',
            border: 0,
          },
        }}
      />
    </CarouselProvider>
  );
};

export default Carousel;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, useStaticQuery } from 'gatsby';

const site = process.env.GATSBY_SITE_DOMAIN || 'fi';
const isMhCom = site === 'com';

const SEO = ({ title, description, image, article, keywords, locale, updatedAt, paths, path }) => {
  const data = useStaticQuery(query);
  const {
    siteMetadata: {
      defaultTitle,
      titleTemplate,
      defaultDescriptions,
      siteUrl,
      defaultImage,
      twitterUsername,
      twitterUserId,
    },
  } = data.site;

  const language = locale?.split('-')?.[0] || 'fi';
  const defaultDescription = defaultDescriptions?.[`${site}_${language}`];
  const seo = {
    name: title,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || siteUrl + defaultImage}`,
    url: `${siteUrl}${paths[(locale || '').substring(0, 2)] || '/'}`,
    keywords,
    locale: locale || 'fi_FI',
    updatedAt,
  };

  let schemaOrgJSONLD = [];
  if (Object.values(paths).includes('/' || '/en' || '/sv')) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: defaultTitle,
      url: siteUrl,
      sameAs: [
        'https://www.facebook.com/matkahuolto',
        'https://www.instagram.com/matkahuolto',
        'https://twitter.com/matkahuolto',
        'http://www.linkedin.com/company/matkahuolto',
        'https://www.youtube.com/channel/UCz1VCI7vgDsSQxQ0Gb_iGDg',
      ],
      logo: seo.image,
    });
  }

  const isSe = (isMhCom && path && (path?.startsWith('/se/') || path === '/se')) || false;

  const hrefLangMaps = {
    se: {
      en: 'en-SE',
      sv: 'sv-SE',
    },
    default: {
      fi: 'fi-FI',
      en: 'en-GB',
      sv: 'sv-SE',
    },
  };

  const hrefLangMap = isSe ? hrefLangMaps.se : hrefLangMaps.default;
  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{ lang: seo.locale.substr(0, 2) }}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        <meta name="og:site_name" content={seo.name} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {/* Schema.org tags */}
        {schemaOrgJSONLD.length > 0 && <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>}
        {article ? <meta property="og:type" content="article" /> : <meta name="og:type" content="website" />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && <meta property="og:description" content={seo.description} />}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary" />
        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
        {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
        {twitterUserId && <meta name="twitter:site:id" content={twitterUserId} />}
        {twitterUserId && <meta name="twitter:creator:id" content={twitterUserId} />}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && <meta name="twitter:description" content={seo.description} />}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        {seo.locale && <meta name="og:locale" content={seo.locale} />}
        {seo.updatedAt && <meta name="og:updated_time" content={seo.updatedAt} />}
        {Object.entries(paths || {}).map(([loc, ePath]) => (
          <link key={loc} rel="alternate" href={`${siteUrl}${ePath}`} hrefLang={hrefLangMap[loc]} />
        ))}
        {isMhCom && paths?.en ? (
          <link key={'en-default'} rel="alternate" href={`${siteUrl}${paths.en}`} hrefLang={'x-default'} />
        ) : null}
        <link rel="canonical" href={seo.url} />
      </Helmet>
    </>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescriptions {
          fi_fi
          fi_en
          fi_sv
          com_en
          com_sv
        }
        siteUrl: url
        defaultImage: image
        twitterUsername
        twitterUserId
      }
    }
  }
`;

export default SEO;

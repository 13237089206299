/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, Flex } from 'theme-ui';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { useTranslation } from 'react-i18next';

const Tooltip = ({ children, tooltip, hideArrow, trigger = ['click', 'hover'], interactive = false, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: trigger || ['click', 'hover'],
    interactive,
    onVisibleChange: setIsVisible,
    visible: isVisible,
    ...props,
  });

  return (
    <>
      <span
        ref={setTriggerRef}
        sx={{ cursor: !trigger.includes('hover') ? 'pointer' : 'default' }}
        tabIndex={0}
        role="tooltip"
      >
        {children}
      </span>

      {visible && (
        <Flex
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
          sx={{
            bg: 'secondary',
            color: 'white',
            borderRadius: 1,
            border: 'none',
            boxShadow: 'dropdown',
            py: 12,
            px: 3,
            m: 2,
            transition: 'opacity .25s',
            zIndex: 389383,
            fontFamily: 'body',
            fontSize: 1,
            maxWidth: ['80vw', '360px'],
          }}
        >
          {!trigger.includes('hover') && (
            <div
              role="button"
              aria-label={t('close')}
              tabIndex={0}
              sx={{
                position: 'absolute',
                top: 0,
                right: 1,
                p: 2,
                fontSize: 4,
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => setIsVisible(false)}
            >
              ✕
            </div>
          )}

          <div sx={{ p: 2 }} role="alert">
            {tooltip}
          </div>
        </Flex>
      )}
    </>
  );
};

export default Tooltip;

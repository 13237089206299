exports.getLinkForPage = (page) => {
  if (!page || !page.slug) return '';
  const slugs = [`/${page.slug.trim()}`];
  let parentPage = page.parentPage;

  while (parentPage && parentPage.slug) {
    const trimmedSlug = parentPage.slug.trim();
    if (!/^home(-com(-en|-sv)?)?$/.test(trimmedSlug)) {
      slugs.unshift(`/${trimmedSlug}`);
    }
    parentPage = parentPage.parentPage;
  }

  return slugs.join('');
};

exports.removeDomain = (url) => {
  // Check if the URL starts with http:// or https://
  if (!url) {
    return '';
  }
  if (url.startsWith('http://') || url.startsWith('https://')) {
    const urlObj = new URL(url);
    return urlObj.pathname; // returns the path part
  } else {
    // Handle cases where the URL doesn't have http/https (relative URL)
    const pathStart = url.indexOf('/');
    return pathStart !== -1 ? url.slice(pathStart) : '/';
  }
};

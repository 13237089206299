/** @jsx jsx */
import { jsx, Flex, Container } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import GlobalStyle from '../styles/global';
import Header from './Header';
import Footer from './Footer';
import Notification from './Notification';
import SEO from '../components/SEO';
import { changeLocale } from '../state/session';
import CookieConsent from '../components/CookieConsent';
import SmartAppBanner from '../components/SmartAppBanner';
import ShopDisturbance from './ShopDisturbance';

export default ({
  index,
  children,
  header,
  showAppBanner,
  path,
  paths,
  title,
  description,
  keywords,
  locale,
  updatedAt,
  darkMode = false,
  showNavigation = true,
  navigation,
  hideNormalNavigation,
  parentSlug,
  footer = true,
  image,
  checkShopDisturbance,
  hideLocales,
}) => {
  const { t: translate, i18n } = useTranslation();
  const notifications = useSelector((state) => state.notifications) || [];
  const [menu, setMenu] = useState(false);

  const sessionLocale = i18n.language;
  const dispatch = useDispatch();

  useEffect(() => {
    const loc = locale && locale.includes('-') ? locale.substring(0, locale.indexOf('-')) : locale;
    if (loc && loc !== sessionLocale) {
      dispatch(changeLocale(loc, paths));
    }
  }, []);

  const pageAvailable = useMemo(() => {
    if (!hideLocales || !hideLocales?.length) {
      return true;
    }
    return !(hideLocales || []).includes(sessionLocale);
  }, [hideLocales, sessionLocale]);

  const path2 =
    (paths || {})[(locale || '').substr(0, 2) || 'fi'] ||
    (typeof window !== 'undefined' ? window.location.pathname : '');
  const pathFirstPart = path2.split('/')[1];

  const corporate = ['yrityksille', 'foretagskunder', 'corporate-customers'].includes(pathFirstPart);
  if (corporate) {
    darkMode = true;
  }

  return (
    <Themed.root>
      {!!showAppBanner && <SmartAppBanner path={path} banner={showAppBanner} />}
      <Global styles={GlobalStyle} />
      <Flex
        sx={{
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <SEO
          path={path}
          title={title}
          description={description}
          keywords={keywords}
          locale={locale}
          updatedAt={updatedAt}
          paths={paths}
          image={image}
        />
        <SkipNav />
        {/**alert && alert*/}
        <CookieConsent />
        {notifications.map((n) => (
          <Notification key={n.id} notification={n} />
        ))}
        <Header
          path={path}
          header={header}
          menu={menu}
          setMenu={setMenu}
          paths={paths}
          showNavigation={showNavigation}
          navigation={navigation}
          minimal={hideNormalNavigation}
          parentSlug={parentSlug}
          hideLocales={hideLocales}
          darkMode={darkMode}
          index={index}
        />
        <div id="skip-nav-content" />
        {checkShopDisturbance ? (
          <ShopDisturbance locale={locale}>
            <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }}>{children}</main>
          </ShopDisturbance>
        ) : !pageAvailable ? (
          <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }}>
            <Container sx={{ textAlign: 'center', py: [5] }}>
              <Themed.h1>{translate('404.title')}</Themed.h1>
            </Container>
          </main>
        ) : (
          <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }} aria-hidden={menu}>
            {children}
          </main>
        )}
        {footer && <Footer corporate={corporate} aria-hidden={menu} path={path} />}
      </Flex>
    </Themed.root>
  );
};

const SkipNav = () => {
  const targetId = 'skip-nav-content';
  const { t } = useTranslation();
  return (
    <a
      href={`#${targetId}`}
      className="skip-nav"
      sx={{
        position: 'absolute',
        top: 2,
        left: 2,
        p: 3,
        zIndex: -1,
        fontSize: 2,
        bg: 'white',
        border: '1px solid blue',
        color: 'primary',
        '&:focus-visible': { zIndex: 101 },
        ':hover': {
          color: 'primary',
          outline: 0,
          border: 0,
        },
      }}
      tabIndex={0}
    >
      {t('skipToContent')}
    </a>
  );
};

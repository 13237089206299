export const isLargeBalticParcel = (product) => {
  if (['LV', 'LT'].includes(product?.countryCode) && product?.deliveryIncluded) return true;
};

export const getUnpaidCashPayments = (shipment) => {
  return shipment?.unpaidCashPayments?.map((row) => row?.priceVatIncluded)?.reduce((total, num) => total + num, 0) || 0;
};

export const ariaLabelForService = (service, translate) => {
  const translate2 = (id, fallback, params) => translate(id, { ...params, defaultValue: fallback });
  const title = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);
  const description = translate2(`buyParcel.services.${service.serviceCode}.description`, service.description, service);
  return `${title}, ${service.price} €, ${description}`;
};

const addWeightProps = (service) => ({
  ...service,
  minWeight: service.fromWeightClass,
  maxWeight: service.toWeightClass || service.override?.weightClass,
});

export const ariaLabelForServiceWithWeightProps = (service, translate) => {
  const translate2 = (id, fallback, params) => translate(id, { ...params, defaultValue: fallback });
  const title = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);
  const description = translate2(
    `buyParcel.services.${service.serviceCode}.description`,
    service.description,
    addWeightProps(service)
  );
  return `${title}, ${service.price} €, ${description}`;
};

export const ariaLabelForServicePoint = (sp) => {
  return sp.officeName + ', ' + sp.officeStreetAddress + ', ' + sp.officeCity;
};

export const ariaLabelForProduct = (product, translate) => {
  let label = '';
  if (product.quantity > 1) {
    label = `sarjapaketti ${product.quantity} kappaletta `;
  }
  label += product.sizeCode + ' paketti ';
  label += product.price + ' €';

  if (product.maxSize) {
    const { height, width, depth } = product.maxSize;
    label += `, koko ${height} kertaa ${width} kertaa ${depth} cm`;
  }

  return label;
};

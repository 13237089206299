import { useStaticQuery, graphql } from 'gatsby';
import { checkLanguageVersion } from '../utils/contentful';
import { filterByChannel, filterByDomain } from './useBlogPosts';
import { useTranslation } from 'react-i18next';

export const useLatestPosts = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { blog, press } = useStaticQuery(graphql`
    query LatestPosts {
      blog: allContentfulBlogPost(filter: { postType: { eq: "Blog" } }, sort: { publishTime: DESC }) {
        edges {
          node {
            id
            metadata {
              tags {
                contentful_id
              }
            }
            title
            slug
            tags
            image {
              gatsbyImageData(width: 480, placeholder: BLURRED, formats: [AUTO, WEBP])
              description
            }
            publishTime(formatString: "D.M.Y")
            languages
            node_locale
            channel
          }
        }
      }
      press: allContentfulBlogPost(filter: { postType: { eq: "Press" } }, sort: { publishTime: DESC }) {
        edges {
          node {
            id
            title
            metadata {
              tags {
                contentful_id
              }
            }
            slug
            publishTime(formatString: "D.M.Y")
            node_locale
            languages
            channel
          }
        }
      }
    }
  `);
  const pressPosts = press.edges
    .filter(filterByChannel)
    .filter(filterByDomain)
    .filter((b) => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages))
    .slice(0, 3);
  const blogPosts = blog.edges
    .filter(filterByChannel)
    .filter(filterByDomain)
    .filter((b) => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages))
    .slice(0, 2);
  return { pressPosts, blogPosts };
};

import i18n from '../localization/i18n';
import * as api from '../utils/api';

import { showNotification } from './notifications';

export const PAGE = {
  SELECT_CUSTOMS_TYPE: 1,
  EMAIL: 2,
  PAY_FEE: 3,
  CHOOSE_PAYMENT_TYPE: 4,
  THANKS: 5,
  CUSTOMS_RECEIPT: 6,
  MANDATE_MATKAHUOLTO: 7,
  PAYMENT_CONFIRMED: 8,
  CUSTOMS_DUTY_PAYMENT: 9,
  LAST: 10,
};

const initialState = {
  page: PAGE.SELECT_CUSTOMS_TYPE,
  country: 'FI',
  services: [],
  jys: {},
  paymentDetails: {},
  mandateMatkahuolto: false,
  mandateDetails: {},
  order: {},
};

const RESET_FLOW = 'CUST_RESET_FLOW';
const SELECT_PAGE = 'CUST_SELECT_PAGE';
const SET_EMAIL = 'CUST_SET_EMAIL';
const SET_PHONE_NUMBER = 'CUST_SET_PHONE_NUMBER';
const SET_PAYMENT_DETAILS = 'CUST_SET_PAYMENT_DETAILS';
const SET_MANDATE_MATKAHUOLTO = 'CUST_SET_MANDATE_MATKAHUOLTO';
const SET_MANDATE_DETAILS = 'CUST_SET_MANDATE_DETAILS';

export const getPageOrder = (isLoggedIn, mandateMatkahuolto, clearanceFeePaid) => {
  const pages = [
    PAGE.SELECT_CUSTOMS_TYPE,
    !isLoggedIn && !mandateMatkahuolto ? PAGE.EMAIL : null,
    mandateMatkahuolto ? PAGE.MANDATE_MATKAHUOLTO : null,
    !clearanceFeePaid ? PAGE.PAY_FEE : null,
    clearanceFeePaid ? PAGE.CUSTOMS_DUTY_PAYMENT : null,
    PAGE.CHOOSE_PAYMENT_TYPE,
    PAGE.CUSTOMS_RECEIPT,
    PAGE.PAYMENT_CONFIRMED,
    PAGE.THANKS,
    PAGE.LAST,
  ];
  return pages.filter((p) => !!p);
};

export const goBack = () => async (dispatch, getState) => {
  const state = getState().customsFlow;
  const isLoggedIn = getState().session.user;
  const { page, seriesId, isUnused, mandateMatkahuolto, ...item } = state;
  const pageOrder = getPageOrder(isLoggedIn, mandateMatkahuolto);
  const curPageIndex = pageOrder.indexOf(state.page);
  const prevPage = curPageIndex > 0 ? pageOrder[curPageIndex - 1] : state.page - 1;

  dispatch({
    type: SELECT_PAGE,
    page: prevPage,
  });
};

export const goNext = () => async (dispatch, getState) => {
  const state = getState().customsFlow;
  const isLoggedIn = getState().session.user;
  const parcel = getState().tracking.parcel;
  const { page, seriesId, isUnused, mandateMatkahuolto, ...item } = state;

  const clearanceFeePaid = parcel?.payments?.some((item) => item.chargeCode === '801' && item.paymentStatus === 'PAID');
  const pageOrder = getPageOrder(isLoggedIn, mandateMatkahuolto, clearanceFeePaid);
  const curPageIndex = pageOrder.indexOf(page);
  const nextPage = curPageIndex >= 0 ? pageOrder[curPageIndex + 1] : state.page + 1;

  if (nextPage !== PAGE.LAST) {
    dispatch({
      type: SELECT_PAGE,
      page: nextPage,
    });
  }
};

export const goToPage =
  (pageNumber, { ...rest }) =>
  async (dispatch, getState) => {
    dispatch({
      type: SELECT_PAGE,
      page: pageNumber,
      ...rest,
    });
  };

export const setPaymentDetails = (paymentDetails) => async (dispatch, getState) => {
  const state = getState().customsFlow.paymentDetails;
  dispatch({
    type: SET_PAYMENT_DETAILS,
    paymentDetails: { ...state, ...paymentDetails },
  });
};

export const setMandateMatkahuolto =
  (mandate = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_MANDATE_MATKAHUOLTO,
      mandateMatkahuolto: mandate,
    });
  };

export const setMandateDetails = (mandateDetails) => async (dispatch, getState) => {
  dispatch({
    type: SET_MANDATE_DETAILS,
    mandateDetails: mandateDetails,
  });
};

export const resetCustomsFlow = () => ({
  type: RESET_FLOW,
});

export const setEmail = (email) => async (dispatch, getState) => {
  dispatch({
    type: SET_EMAIL,
    email: email,
  });
};

export const createOrder = (paymentOptions) => async (dispatch, getState) => {
  try {
    const { paymentDetails, mandateDetails } = getState().customsFlow;
    const isLoggedIn = getState().session.user;
    const language = i18n.language;
    // const { phone } = getState().session.user;
    const response = await api.createCustomsOrder(
      {
        email: isLoggedIn ? isLoggedIn.email : paymentDetails.email,
        ...paymentDetails,
        ...mandateDetails,
      },
      paymentOptions,
      language,
      isLoggedIn ? isLoggedIn.phoneNumber : null
    );
    // const { redirectUrl, orderId } = response;
    return response;
  } catch (error) {
    console.warn('Failed to create delivery order:', error);
    dispatch(showNotification('updateFailed'));
  }
};

export const getClearancePrices = () => async (dispatch, getState) => {
  try {
    const { services } = await api.getClearancePrices();
    const givenServiceCode = getState().customsFlow.paymentDetails.customsClearance;
    const service = services.find(({ customsClearance }) => customsClearance === givenServiceCode);
    dispatch(setPaymentDetails({ priceVatIncluded: service.priceVatIncluded }));
  } catch (error) {
    console.warn('Failed to get clearance prices:', error);
    dispatch(showNotification('updateFailed'));
  }
};

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case RESET_FLOW:
      return { ...initialState };
    case SELECT_PAGE:
      return { ...state, ...params };
    case SET_EMAIL:
      return { ...state, ...params };
    case SET_PHONE_NUMBER:
      return { ...state, ...params };
    case SET_PAYMENT_DETAILS:
      return { ...state, ...params };
    case SET_MANDATE_MATKAHUOLTO:
      return { ...state, ...params };
    case SET_MANDATE_DETAILS:
      return { ...state, ...params };

    default:
      return state;
  }
};

/** @jsx jsx */
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, jsx, Label } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import cx from 'clsx';
import { useDropzone } from 'react-dropzone';

const DropzoneField = ({ name, newLabel, extraLabel, placeholder, clearButtonText, zoneActiveText }) => {
  const { t: translate } = useTranslation();
  const maxSize = 5000;
  const label = newLabel || translate('feedback.attachmentSize', { maxSize: maxSize / 1000 });

  return (
    <Box
      sx={{
        '.error': { color: 'danger' },
        '.dropzone-wrapper .dropzone': {
          display: 'flex',
          justifyContent: 'center',
          borderWidth: 2,
          borderColor: 'border',
          borderStyle: 'dashed',
          borderRadius: 2,
          backgroundColor: 'grayLighter',
          padding: 2,
        },
        '.dropzone': {
          alignItems: 'center',
        },
        img: {
          maxHeight: '200px',
          maxWidth: '200px',
          mx: 'auto',
          display: 'block',
        },
      }}
    >
      <Label sx={{ mb: extraLabel ? 0 : 1 }}>{label}</Label>
      {extraLabel && <Box sx={{ mb: 1 }}>{extraLabel}</Box>}
      <DropZone
        name={name}
        placeholder={placeholder}
        zoneActiveText={zoneActiveText}
        clearButtonText={clearButtonText}
      />
    </Box>
  );
};

const DropZone = ({
  disabled,
  id,
  name,
  accept,
  zoneActiveText,
  fileCount,
  disabledText,
  placeholder,
  clearButtonText,
  className,
  multiple = true,
  ...rest
}) => {
  const [{ value }, , { setValue }] = useField(name);
  const [files, setFiles] = useState(value || []);

  const onDrop = (droppedFiles) => {
    const newFiles = multiple ? files.concat(droppedFiles) : droppedFiles;
    setFiles(newFiles);
    setValue(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept,
    onDrop,
    disabled,
    multiple,
    ...rest,
  });

  const handleClearFiles = useCallback(() => {
    setFiles([]);
    setValue([]);
  }, [setValue, setFiles]);

  return (
    <>
      <div
        {...getRootProps()}
        className={cx('dropzone', className, {
          'dropzone--active': isDragActive,
          'dropzone--disabled': disabled,
        })}
        data-testid="fui-dropzone"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderWidth: 2,
          borderColor: 'border',
          borderStyle: 'dashed',
          borderRadius: 2,
          backgroundColor: 'grayLighter',
          padding: 2,
          alignItems: 'center',
          img: {
            maxHeight: '200px',
            maxWidth: '200px',
            mx: 'auto',
            display: 'block',
          },
        }}
      >
        {disabled ? (
          <p className="text">{disabledText}</p>
        ) : (
          <>
            <input {...getInputProps()} id={id || name} name={name} />
            {files.length ? (
              files.map((file) => {
                if (file.type.includes('image')) {
                  return (
                    <img key={file.name} src={URL.createObjectURL(file)} className="img-thumbnail" alt={file.name} />
                  );
                }

                return (
                  <div key={file.name} className="icon-wrapper">
                    <div className="icon">
                      <i title={file.name.split('.').pop()} />
                    </div>
                    <p>{file.name.split('.').shift()}</p>
                  </div>
                );
              })
            ) : isDragActive ? (
              <p className="text">{zoneActiveText}</p>
            ) : (
              <p className="text">{placeholder}</p>
            )}
            {fileCount && (
              <div className="file-count">{`Accepted ${files.length}, rejected ${fileRejections.length} files`}</div>
            )}
          </>
        )}
      </div>
      {files.length > 0 && (
        <Button variant="plain" onClick={handleClearFiles}>
          {clearButtonText}
        </Button>
      )}
    </>
  );
};

export default DropzoneField;

/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby';
import { Container, jsx } from 'theme-ui';
import RichText from './RichText';

export default ({ locale, children }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulDisturbance(stopPackagePurchases: { eq: true }) {
        reason {
          raw
        }
      }
    }
  `);
  if (!data || !data.contentfulDisturbance) {
    return children;
  }
  return (
    <Container sx={{ minHeight: '240px', my: 3 }}>
      <RichText content={data.contentfulDisturbance.reason} locale={locale} />
    </Container>
  );
};

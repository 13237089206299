/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { keyframes } from '@emotion/react';

const spinnerSize = {
  small: { size: 24, borderWidth: '2px' },
  medium: { size: 36, borderWidth: '4px' },
  large: { size: 72, borderWidth: '8px' },
};

const rotateAnimation = keyframes`
    0% {
        transform: translateZ(0) rotate(0deg) translateX(-50%) translateY(-50%);
    }
    100% {
        transform: translateZ(0) rotate(1turn) translateX(-50%) translateY(-50%);
    }
`;

export default ({ color = 'secondary', size = 'small', position = 'absolute' }) => (
  <Box
    sx={{
      position,
      top: '50%',
      left: '50%',
      zIndex: 200,
      width: spinnerSize[size].size,
      height: spinnerSize[size].size,
      borderWidth: spinnerSize[size].borderWidth,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: '50%',
      borderLeftColor: 'currentColor',
      animation: `${rotateAnimation} .6s linear infinite`,
      color: color,
      transformOrigin: '0 0',
      transform: 'translateZ(0) translateX(-50%) translateY(-50%)',

      '::after': {
        position,
        top: '50%',
        left: '50%',
        content: "''",
        zIndex: 'mask',
        boxSizing: 'content-box',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        opacity: 0.3,
        borderRadius: '50%',
        borderWidth: spinnerSize[size].borderWidth,
        borderStyle: 'solid',
        borderColor: 'currentColor',
        transformOrigin: '0 0',
        transform: 'translateZ(0) translateX(-50%) translateY(-50%)',
      },
    }}
  />
);

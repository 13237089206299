/** @jsx jsx */
import { jsx, Box, Flex, Container } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import React from 'react';
import Link from '../Link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Card from '../Card';
import { getLinkForPage } from '../../utils/links';
import { readableColor } from 'polished';
import { useTranslation } from 'react-i18next';

function paragraphWordWrap(str) {
  // NU-313 hotfix. TODO: Maybe there is a cleaner solution, but this was urgently needed.
  // Used to add a word wrap WBR to email addresses that otherwise do not word wrap correctly.
  const regex = /<p>([^<@\s]+@[^>\s]+)<\/p>/g;
  const replacedStr = str.replace(regex, (match, email) => {
    const replacedEmail = email.replace('@', '<wbr>@');
    return `<p>${replacedEmail}</p>`;
  });
  return replacedStr;
}

function removeHtml(text, tagsToRemove) {
  const tagsRegex = new RegExp(`<\/?(${tagsToRemove.join('|')})[^>]*>`, 'gi');
  return text?.replace(tagsRegex, '') || '';
}

const HighlightCard = ({ content, imageBackgroundColor }) => {
  return (
    <Card
      image={content.image}
      title={content.headline}
      footer={content.footer}
      imageBackgroundColor={imageBackgroundColor}
      border={content.visualStyle === 'Card'}
    >
      {content.copy && (
        <Box
          dangerouslySetInnerHTML={{
            __html: paragraphWordWrap(content.copy.childMarkdownRemark.html),
          }}
          sx={{ '*:last-child': { mb: 0 }, a: { textDecoration: 'underline' } }}
        />
      )}
      {content.ctaPage || content.ctaLink ? (
        <Link
          noTitleFix
          to={content.ctaPage ? getLinkForPage(content.ctaPage) : content.ctaLink}
          sx={{ fontWeight: 'medium', display: 'inline-block', mt: 3, textDecoration: 'underline' }}
        >
          {content.ctaTitle && content.ctaTitle + ' →'}
        </Link>
      ) : null}
    </Card>
  );
};

const DefaultImage = ({ reverse, image }) => (
  <Box
    sx={{
      width: ['100vw', null, '50%'],
      order: reverse ? [null, null, 1] : [null, null, 0],
    }}
  >
    <GatsbyImage
      image={getImage(image)}
      alt={image?.description || ''}
      sx={{
        outline: 0,
        borderRadius: 2,
      }}
    />
  </Box>
);

const TwoImages = ({ reverse, image, image2 }) => (
  <Box
    sx={{
      width: '100%',
      maxWidth: '1024px',
      mx: 'auto',
      order: reverse ? [null, null, 1] : [null, null, 0],
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 3,
    }}
  >
    <GatsbyImage
      image={getImage(image)}
      alt={image?.description || ''}
      sx={{
        bg: 'secondary',
        borderRadius: 2,
      }}
    />
    {image2 && (
      <GatsbyImage
        image={getImage(image)}
        alt={image?.description || ''}
        sx={{
          bg: 'secondary',
          borderRadius: 2,
        }}
      />
    )}
  </Box>
);

const SmallImage = ({ reverse, image }) => (
  <Box
    sx={{
      width: '50%',
      order: reverse ? [null, null, 1] : [null, null, 0],
    }}
  >
    <GatsbyImage
      image={getImage(image)}
      alt={image?.description || ''}
      sx={{
        bg: 'secondary',
        borderRadius: 2,
      }}
    />
  </Box>
);

const Quote = ({ content }) => {
  return (
    <Flex sx={{ flexDirection: 'row' }}>
      <span sx={{ fontSize: 140, mr: 3, alignSelf: 'flex-start', mt: '-0.35em', mb: '-1em' }}>&rdquo;</span>
      <div
        sx={{ fontSize: [2, null, 3] }}
        dangerouslySetInnerHTML={{
          __html: removeHtml(content, ['blockquote', 'p']),
        }}
      />
    </Flex>
  );
};

const HighlightBox = ({
  content,
  reverse,
  card = false,
  theme = null,
  modern = false,
  backgroundColor,
  imageBackgroundColor,
}) => {
  const isQuote =
    content?.copy?.childMarkdownRemark?.html?.trim()?.startsWith('<blockquote>') &&
    content?.copy?.childMarkdownRemark?.html?.trim()?.endsWith('</blockquote>');

  let titleColor;
  let textColor;
  let highlightColor;
  try {
    titleColor = readableColor(backgroundColor, '#001E60', 'white');
    highlightColor = readableColor(titleColor, '#001E60', 'white');
    textColor = readableColor(backgroundColor, 'black', 'white');
  } catch {
    titleColor = theme;
    textColor = 'black';
    highlightColor = 'white';
  }

  return (
    <>
      {card || content.visualStyle === 'Card' ? (
        <HighlightCard content={content} imageBackgroundColor={imageBackgroundColor} />
      ) : content.visualStyle === 'Two Images' ? (
        <TwoImages reverse={reverse} image={content.image} image2={content.image2} />
      ) : (
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: content.visualStyle === 'Small Image' ? 'flext-start' : 'center',
            flexWrap: ['wrap', null, 'nowrap'],
          }}
        >
          {content.image &&
            (content.visualStyle === 'Small Image' ? (
              <SmallImage reverse={reverse} image={content?.imageSquare || content.image} />
            ) : content.visualStyle === 'Two Images' ? (
              <TwoImages reverse={reverse} image={content.image} image2={content.image2} />
            ) : (
              <DefaultImage reverse={reverse} image={content.image} />
            ))}
          <Box
            sx={{
              width: content.visualStyle === 'Small Image' ? ['100%', null, '75%'] : ['100%', null, '50%'],
              order: reverse ? [null, null, 0] : [null, null, 1],
              pl: reverse ? 0 : [0, null, 4, 5],
              pr: reverse ? [0, null, 4, 5] : 0,
              mt: [3, null, 0, 0],
            }}
          >
            {content.headline && (
              <Themed.h2
                sx={{
                  hyphens: 'auto',
                  variant: 'text.title',
                  mt: 0,
                  color: backgroundColor ? titleColor : modern ? 'text' : theme ? theme : null,
                }}
              >
                {content.headline}
              </Themed.h2>
            )}
            {content.copy && (
              <>
                {isQuote ? (
                  <Quote content={content.copy.childMarkdownRemark.html} />
                ) : (
                  <Box
                    sx={{
                      fontSize: [17, null, 19],
                      mb: 3,
                      color: textColor,
                      p: {
                        color: textColor,
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: content.copy.childMarkdownRemark.html,
                    }}
                  />
                )}
              </>
            )}
            {content.ctaPage || content.ctaLink ? (
              <Link
                noTitleFix
                to={content.ctaPage ? getLinkForPage(content.ctaPage) : content.ctaLink}
                sx={{
                  display: 'inline-block',
                  border: 0,
                  borderRadius: 3,
                  bg: backgroundColor ? titleColor : theme ? theme : 'primary',
                  color: backgroundColor ? highlightColor : 'white',
                  fontWeight: 'medium',
                  px: 3,
                  py: 12,
                  whiteSpace: 'nowrap',
                  ':hover': { textDecoration: 'underline' },
                  ':focus': { boxShadow: 'none', outline: 'none' },
                  ':disabled': {
                    opacity: 0.35,
                    cursor: 'not-allowed',
                    ':hover': {
                      boxShadow: 'none',
                    },
                  },
                }}
              >
                {content.ctaTitle} &rarr;
              </Link>
            ) : null}
          </Box>
        </Flex>
      )}
    </>
  );
};

const Highlight = ({ backgroundColor, ...rest }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  if (backgroundColor) {
    return (
      <Box
        lang={lang || 'fi'}
        sx={{
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          backgroundColor,
        }}
      >
        <Container sx={{ py: [3, null, 5] }}>
          <HighlightBox backgroundColor={backgroundColor} {...rest} />
        </Container>
      </Box>
    );
  } else {
    return <HighlightBox {...rest} />;
  }
};

export default Highlight;

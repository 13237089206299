/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Link from '../../components/Link';
import { Button } from '..';
import Widget from './Widget';
import themes from './themes';

export default (props) => {
  const { title, description, ctaTitle, ctaLink, visualStyle } = props.data;
  const { t: translate } = useTranslation();
  const theme = themes?.[visualStyle] || themes['Bluelight'];

  return (
    <Widget title={title ? title : translate('seriesWidget.title')} titleColor={theme.titleColor} {...props}>
      <Box>{description ? description.description : translate('seriesWidget.description')}</Box>
      <Flex sx={{ flexWrap: 'wrap', fontSize: 2, mt: 3, gap: 2 }}>
        <Button variant="secondary" as={Link} to={ctaLink ? ctaLink : '/osta-sarjapaketti'}>
          {ctaTitle ? ctaTitle : translate('seriesWidget.buySeriesPackage')}
        </Button>
        {!props.noSendButton && (
          <Button as={Link} to="/multi-parcel" variant="secondary">
            {translate('seriesWidget.sendSeriesPackage')} &rarr;
          </Button>
        )}
      </Flex>
    </Widget>
  );
};

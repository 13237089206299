/** @jsx jsx */
import { jsx, Flex, Box, Container } from 'theme-ui';
import { Link } from 'gatsby';
import { getLinkForPage } from '../../utils/links';

const rounded = true;

export default ({ links }) => (
  <Container>
    <Flex
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {links &&
        links
          .filter((link) => link && link.page)
          .map((link, i) => (
            <Link
              key={i}
              sx={{
                display: 'flex',
                flex: 'none',
                width: ['50%', 144],
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignItems: 'center',
                textOverflow: 'ellipsis',
                p: 2,
                ':hover > div:first-of-type': {
                  boxShadow: 'inset 0 0 0 100em rgba(0,0,0,.1)',
                },
              }}
              to={getLinkForPage(link.page)}
            >
              <Flex
                sx={{
                  display: 'flex',
                  flex: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: [64, 80],
                  width: [64, 80],
                  bg: 'primary',
                  borderRadius: rounded ? '100%' : [16, 24],
                  img: {
                    maxWidth: [40, 48],
                    maxHeight: [40, 48],
                    filter:
                      'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)',
                  },
                }}
              >
                {link.icon && <img src={link.icon.file.url} width="48" height="48" aria-hidden="true" />}
              </Flex>
              <Flex
                sx={{
                  flex: 'none',
                  mt: 2,
                  justifyContent: 'center',
                  fontSize: [1, 2],
                  fontWeight: 'medium',
                  textAlign: 'center',
                  lineHeight: 'heading',
                  color: 'secondary',
                  width: '100%', // Fixes IE11
                }}
              >
                {link.title}
              </Flex>
            </Link>
          ))}
    </Flex>
  </Container>
);

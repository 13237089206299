/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Themed } from '@theme-ui/mdx';
import { Link as GatsbyLink } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { shade } from 'polished';
import { Helmet } from 'react-helmet-async';
import { Box, Embed, Flex, Grid, jsx, useThemeUI } from 'theme-ui';
import { Link } from '.';
import Accordion from '../components/Accordion';
import BlockQuote from '../components/BlockQuote';
import Contact from '../components/Contact';
import { PageTable, WidgetList } from '../components/content-modules';
import PageGallery from '../components/PageGallery';
import VideoGallery from '../components/VideoGallery';
import { useBlogPosts } from '../hooks/useBlogPosts';
import { usePages } from '../hooks/usePages';
import MapAndFilters from '../map/MapAndFilters';
import { isBrowser } from '../utils';
import { getLinkForPage } from '../utils/links';
import Button from './Button';
import Carousel from './Carousel';
import { Highlight } from './content-modules';
import ContentfulComponent from './ContentfulComponent';
import LogoCarousel from './LogoCarousel';
import LogoCarouselNew from './LogoCarouselNew';
import AlertNotification from './AlertNotification';

const isMhCom = process.env.GATSBY_SITE_DOMAIN === 'com';

function textToAnchor(text) {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
}

function getNodeAnchor(node) {
  return [node.nodeType === 'text' ? textToAnchor(node.value) : null, ...(node.content || []).map(getNodeAnchor)]
    .filter(Boolean)
    .join('-');
}

const options = (
  locale,
  parentId,
  faqData = [],
  assets = {},
  linkStyles = {},
  paragraphStyles = {},
  layout = null
) => ({
  renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.BOLD]: (text) => <Themed.strong>{text}</Themed.strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Themed.p sx={paragraphStyles}>{children}</Themed.p>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Themed.h1 id={getNodeAnchor(node)} sx={{ wordWrap: 'break-word', hyphens: 'auto' }}>
        {children}
      </Themed.h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => <Themed.h2 id={getNodeAnchor(node)}>{children}</Themed.h2>,
    [BLOCKS.HEADING_3]: (node, children) => <Themed.h3>{children}</Themed.h3>,
    [BLOCKS.HEADING_4]: (node, children) => <Themed.h4>{children}</Themed.h4>,
    [BLOCKS.HEADING_5]: (node, children) => <Themed.h5>{children}</Themed.h5>,
    [BLOCKS.HEADING_6]: (node, children) => <Themed.h6>{children}</Themed.h6>,
    [BLOCKS.UL_LIST]: (node, children) => <Themed.ul>{children}</Themed.ul>,
    [BLOCKS.OL_LIST]: (node, children) => <Themed.ol>{children}</Themed.ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <Themed.li>{children}</Themed.li>,
    [BLOCKS.HR]: (node, children) => <Themed.hr />,
    [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
    [INLINES.ASSET_HYPERLINK]: ({
      content: [{ value: text } = {}],
      data: { target: { file: { url } = {}, title } = {} },
    }) => {
      if (url && (text || title))
        return (
          <a href={url} sx={{ ...linkStyles, textDecoration: 'underline' }} target="_blank" rel="noreferrer">
            {text || title}
          </a>
        );
    },
    [INLINES.HYPERLINK]: (node) => {
      const { value } = (node.content || [])[0] || {};
      const { uri } = node.data || {};
      const [renderVideo, setRenderVideo] = useState(false);

      useEffect(() => {
        setRenderVideo(isBrowser);
      }, [isBrowser]);

      try {
        const url = new URL(uri || '');
        const hosts = isMhCom ? ['www.matkahuolto.com', 'matkahuolto.com'] : ['www.matkahuolto.fi', 'matkahuolto.fi'];
        if (hosts.includes(url.host)) {
          const to = url.pathname + url.search + url.hash;
          return (
            <GatsbyLink to={to} sx={linkStyles}>
              {value}
            </GatsbyLink>
          );
        } else if (renderVideo && uri.includes('youtube.com/embed')) {
          return (
            <Embed
              title={value}
              src={uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></Embed>
          );
        }
      } catch (error) {
        // ignore, render absolute uri
      }
      return (
        <a href={uri} sx={linkStyles}>
          {value}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node) => {
      const targetId = node.data.target___NODE || (node.data.target && node.data.target.sys && node.data.target.sys.id);
      if (!targetId) {
        return;
      }
      const pages = usePages();
      const blogPosts = useBlogPosts();
      let referencedPage = pages.find((page) => page.node.id === targetId || page.node.contentful_id === targetId);
      let isBlogPost = false;
      if (!referencedPage) {
        referencedPage = blogPosts.find((page) => page.node.id === targetId || page.node.contentful_id === targetId);
        if (!referencedPage) {
          console.log(`node ${parentId} invalid hyperlink to graphql id: ${targetId}`);
        } else {
          isBlogPost = true;
        }
      }
      if (referencedPage) {
        let parentSlug = (referencedPage.node.parentPage && getLinkForPage(referencedPage.node.parentPage)) || '';
        if (isBlogPost) {
          parentSlug = referencedPage.node.postType === 'Blog' ? '/blog' : '/news';
        }
        return (
          <GatsbyLink to={`${parentSlug}/${referencedPage.node.slug}`} sx={linkStyles}>
            {node.content[0].value}
          </GatsbyLink>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: ({ data: { target } }) => {
      switch (target && target.__typename) {
        // TODO: handle svg icons and gatsby links?
        case 'ContentfulLayoutLink':
          const {
            icon: { file: { url: imageUrl } = {}, title: imageTitle } = {},
            title: linkTitle,
            url: linkUrl,
          } = target;
          return (
            <a href={linkUrl}>
              {imageUrl ? (
                <img src={imageUrl} alt={imageTitle ? imageTitle : ''} sx={{ maxWidth: '100%' }} />
              ) : (
                linkTitle
              )}
            </a>
          );

        default:
          console.log(`node ${parentId} INLINES.EMBEDDED_ENTRY:`, target);
          break;
      }
      return;
    },
    [BLOCKS.EMBEDDED_ENTRY]: ({ data: embData }) => {
      const { target } = embData;
      switch (target && target.__typename) {
        case 'ContentfulAccordion':
          return <Accordion title={target.title} content={target.body} locale={locale} id={target?.contentful_id} />;
        case 'ContentfulComponent':
          const { data, template, body } = target;
          let parsedData;
          try {
            parsedData = JSON.parse(data.internal.content);
          } catch {}

          const dataNotRequiredComponents = [
            'Notification',
            'ParcelWidget',
            'ParcelTracking',
            'MultiParcelWidget',
            'TicketWidget',
            'SMESelection',
          ];
          if (!parsedData && !dataNotRequiredComponents.includes(template)) {
            return;
          }
          const style = ['ParcelWidget', 'MultiParcelWidget', 'TicketWidget', 'SMESelection'].includes(template)
            ? { my: 4 }
            : {};

          return (
            <ContentfulComponent
              data={parsedData}
              template={template}
              locale={locale}
              assets={assets}
              body={body}
              sxx={style}
            />
          );
        case 'ContentfulServicePointMap':
          return (
            <Box
              sx={{
                width: '100vw',
                position: 'relative',
                left: '50%',
                right: '50%',
                marginLeft: '-50vw',
                marginRight: '-50vw',
              }}
            >
              <MapAndFilters height="50" resultsMaxWidth={null} />
            </Box>
          );
        case 'ContentfulContact':
          const contactProps = pick(target, ['name', 'jobTitle', 'email', 'phoneNumber']);
          const info = get(target, 'info.childMarkdownRemark.html');
          return <Contact {...contactProps} image={target.image.file.url} info={info} />;
        case 'ContentfulFrequentlyAskedQuestion':
          const { question, answer, contentful_id } = target;
          return (
            <Accordion variant="faq" title={question} id={contentful_id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: answer.childMarkdownRemark.html,
                }}
                sx={{ a: { textDecoration: 'underline' } }}
              />
            </Accordion>
          );

        case 'ContentfulLayoutWidgetList':
          return (
            <Box sx={{ mb: 4 }}>
              <WidgetList widgets={target.widgets} sxx={{ mt: 0 }} />
            </Box>
          );
        case 'ContentfulFrequentlyAskedQuestionCategory':
          const { headline, frequentlyAskedQuestions } = target;
          let faq = [];
          frequentlyAskedQuestions.forEach((q) => {
            const { question, answer, contentful_id } = q;
            faq.push(
              <Accordion variant="faq" title={question} id={contentful_id}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: answer.childMarkdownRemark.html,
                  }}
                  sx={{ a: { textDecoration: 'underline' } }}
                />
              </Accordion>
            );
            faqData.push({
              '@type': 'Question',
              name: question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: answer,
              },
            });
          });
          return (
            <Box sx={{ mb: 3 }}>
              {/*<Themed.h3 children={headline} />*/}
              {faq}
            </Box>
          );

        case 'ContentfulPageTable':
          return <PageTable content={target.body && target.body.body} />;
        case 'ContentfulCarousel':
          const { logoCarousel = false, logoCarouselColor } = target;
          if (logoCarousel) {
            return <LogoCarousel slides={target.slides} color={logoCarouselColor} sx={{ mb: 4 }} />;
          } else {
            return <Carousel slides={target.slides} />;
          }

        case 'ContentfulLogoCarousel':
          return <LogoCarouselNew slides={target.slides} color={target.color || '#FFFFFF'} sx={{ my: 45 }} />;

        case 'ContentfulPageSection':
          if (target.contentType === 'Video') {
            return <VideoGallery title={target.title} videos={target.images} />;
          }
          if (target.contentType === 'Image') {
            return <PageGallery title={target.title} images={target.images} />;
          }
          if (['Highlight', 'Grid'].includes(target.contentType)) {
            let highlights = target.content.map((c) => {
              return <Highlight content={c} key={c.contentful_id} card imageBackgroundColor={c.imageBackgroundColor} />;
            });

            const isLinkGrid = target.contentType === 'Grid';
            const numberOfColumns =
              !target?.columns || target?.columns === 'automatic' ? highlights.length : Number(target?.columns);
            return (
              <Grid
                sx={{
                  gridGap: [3, null, null, 4],
                  gridTemplateColumns: ['1fr', null, `repeat(${numberOfColumns}, 1fr)`],
                  gridTemplateRows: ['auto', null, '1fr'],
                  my: isLinkGrid ? 0 : [4, 5],
                  ml: 0,
                  // causes weird behaviour with highlights in page section
                  //mr: layout === 'narrow' || highlights?.length >= 3 ? 0 : [0, null, null, -4, -6],
                }}
              >
                {highlights}
              </Grid>
            );
          }
          break;
        case 'ContentfulLayoutHighlight':
          return (
            <Box
              sx={{
                //my: [3, 4],
                my: 50,
                ml: 0,
              }}
            >
              <Highlight content={target} key={target.contentful_id} backgroundColor={target.backgroundColor} />
            </Box>
          );
        case 'ContentfulLayoutLink':
          if (!target.isCtaLink) {
            return null;
          }
          return (
            <Box sx={{ my: 4 }}>
              <Button as={Link} to={{ link: target.url, external: true }}>
                {target.title}
              </Button>
            </Box>
          );
        case 'ContentfulNotification':
          return <AlertNotification sxx={{}} body={target.body} locale={locale} />;
        default:
          console.log(`node ${parentId} EMBEDDED_ENTRY`, embData);
          break;
      }
      return;
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data: { target } }) => {
      const { contentful_id } = target || {};
      const asset = assets[contentful_id];
      if (asset) {
        let cols = asset.data && asset.data.length ? Object.keys(asset.data[0]) : [];
        let headerRow = cols.map((col, index) => <Themed.th key={'h' + index}>{col}</Themed.th>);
        let rows = (asset.data || []).map((row, index) => (
          <Themed.tr key={'r' + index}>
            {cols.map((col, jndex) => (
              <Themed.td key={'c' + jndex}>{row[col]}</Themed.td>
            ))}
          </Themed.tr>
        ));

        return (
          <Themed.table>
            <thead>
              <Themed.tr>{headerRow}</Themed.tr>
            </thead>
            <tbody>{rows}</tbody>
          </Themed.table>
        );
      }

      if (!target || !target.file) {
        console.log(`node ${parentId} EMBEDDED_ASSET:`, target);
        return;
      }

      const supportedVideoExtensions = ['mp4', 'webm', 'avi', 'mov'];
      const fileExtension = target?.file?.url?.split('.')?.pop();
      if (supportedVideoExtensions.includes(fileExtension)) {
        return (
          <Flex>
            <Box
              sx={{
                my: [4],
                mx: [-3, 0],
                bg: 'secondary',
                borderRadius: [0, 2],
                img: {
                  display: 'block',
                },
              }}
            >
              <video src={target?.file?.url} controls sx={{ maxWidth: '100%', backgroundColor: 'blueLighter' }}>
                Your browser does not support the video tag.
              </video>
            </Box>
          </Flex>
        );
      } else {
        return (
          <Flex>
            <Box
              sx={{
                my: [4],
                mx: [-3, 0],
                bg: 'secondary',
                borderRadius: [0, 2],
                img: {
                  display: 'block',
                  // mixBlendMode: 'screen',
                },
              }}
            >
              <img src={target.file.url} sx={{ maxWidth: '100%' }} />
            </Box>
          </Flex>
        );
      }
      /*
      return (
        <Image
          fluid={image}
          sx={{
            my: [4],
            mx: [-3, 0],
            bg: 'secondary',
            borderRadius: [0, 2],
            img: {
              mixBlendMode: 'screen',
            },
          }}
        />
      );*/
    },
  },
});

export default ({ content, locale, parentId = 'not_set', assets, layout, paragraphStyles }) => {
  const languages = {
    en: 'en-US',
    'en-US': 'en-US',
    fi: 'fi-FI',
    'fi-FI': 'fi-FI',
    sv: 'sv-SE',
    'sv-SE': 'sv-SE',
  };
  const language = languages[locale] || 'fi-FI';
  const faqData = [];
  const { theme } = useThemeUI();
  const linkStyles = {
    textDecoration: 'underline',
    ':hover': {
      color: shade(0.25, theme.colors.primary),
    },
  };

  const opts = options(language, parentId, faqData, assets, linkStyles, paragraphStyles, layout);
  const doc = (
    <>
      {renderRichText(content, opts)}
      {faqData.length > 0 && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'FAQPage',
              mainEntity: faqData.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i),
            })}
          </script>
        </Helmet>
      )}
    </>
  );
  return doc;
};

import { css } from '@emotion/react';
import theme from '../gatsby-plugin-theme-ui';
import './fonts.css';

const GlobalStyle = css`
  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    background-color: transparent;
    text-decoration: none;
    color: ${theme.colors.primary};
    -webkit-text-decoration-skip: objects;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  p {
    color: ${theme.colors.black};
  }

  input,
  textarea,
  button {
    font-family: ${theme.fonts.body};
  }
`;

export default GlobalStyle;

/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Link2 } from '../Link';
import { Button } from '..';
import Widget from './Widget';
import themes from './themes';

export default (props) => {
  const { title, description, ctaTitle, ctaLink, visualStyle, backgroundColor, titleColor, textColor } = props.content;
  const theme = themes?.[visualStyle] || themes['Bluelight'];

  return (
    <Widget
      title={title}
      bg={backgroundColor || theme.bg}
      color={textColor || theme.color}
      titleColor={titleColor || theme.titleColor}
      {...props}
    >
      {description && (
        <Box
          dangerouslySetInnerHTML={{ __html: parseLists(description?.description || '') }}
          sx={{ ol: { p: 0, my: 0, mx: 3 }, ul: { p: 0, my: 0, mx: 3 } }}
        ></Box>
      )}
      {ctaLink && ctaTitle && (
        <Flex sx={{ mt: 3 }}>
          <Button variant="smallSecondary" as={Link2} to={ctaLink}>
            {ctaTitle}
          </Button>
        </Flex>
      )}
    </Widget>
  );
};

const parseLists = (text) => {
  /* 
    Helper function to convert plaintext lists into HTML lists. 
    Widget description is defined as plaintext in contentful and changing it would result in loss of data. 
    This allows to render lists without any changes to Contentful.
  */
  const lines = text.split('\n');
  let html = '';
  let isNumberedList = false;
  let isBulletList = false;

  lines.forEach((line) => {
    const trimmedLine = line.trim();
    if (/^\d+\.\s/.test(trimmedLine)) {
      if (!isNumberedList) {
        html += '<ol>';
        isNumberedList = true;
      }
      html += `<li>${trimmedLine.replace(/^\d+\.\s/, '')}</li>`;
    } else if (/^[-*]\s/.test(trimmedLine)) {
      if (!isBulletList) {
        html += '<ul>';
        isBulletList = true;
      }
      html += `<li>${trimmedLine.replace(/^[-*]\s/, '')}</li>`;
    } else {
      if (isNumberedList) {
        html += '</ol>';
        isNumberedList = false;
      }
      if (isBulletList) {
        html += '</ul>';
        isBulletList = false;
      }
      if (trimmedLine) {
        html += trimmedLine;
      }
    }
  });

  if (isNumberedList) {
    html += '</ol>';
  }
  if (isBulletList) {
    html += '</ul>';
  }

  return html;
};

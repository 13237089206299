/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import Link from '../components/Link';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '../hooks/useNavigation';
import { getLinkForPage } from '../utils/links';
import { useColors } from '../hooks/useColors';

const NavLink = (props) => {
  const { hiContrast } = useColors(props?.darkMode);
  return (
    <Link
      sx={{
        position: 'relative',
        mx: [0, 0, 0, 3],
        py: 2,
        fontSize: 16,
        fontWeight: 'medium',
        lineHeight: 1,
        color: ['white', null, null, hiContrast],
        transition: 'color .15s ease',
        ':hover, &[aria-current="page"]': {
          color: hiContrast,
        },
        '&::after': {
          position: 'absolute',
          bottom: -1,
          left: 2,
          right: 2,
          height: 2,
          bg: hiContrast,
          content: '""',
          width: 0,
        },
        '&[aria-current="page"]::after': {
          width: [0, 0, 0, 'auto'],
        },
      }}
      partiallyActive={true}
      noTitleFix
      noPathTranslation
      {...props}
    >
      {props.children}
    </Link>
  );
};

const Navigation = ({ navigation, darkMode, ...props }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { navigation: defaultNavLinks, business } = useNavigation(locale);
  const path2 =
    (props.paths || {})[(locale || '').substr(0, 2) || 'fi'] ||
    (typeof window !== 'undefined' ? window.location.pathname : '');
  const path = path2.split('/')[1];
  const links =
    (navigation && navigation.links) ||
    (['yrityksille', 'foretagskunder', 'corporate-customers'].includes(path) ? business : defaultNavLinks);

  return (
    <Flex
      {...props}
      sx={{
        flex: 'auto',
        flexDirection: ['column', null, null, 'row'],
        alignItems: 'flex-start',
        justifyContent: ['center', null, null, 'flex-start'],
        width: ['100%', null, null, 'auto'],
        mx: [2, 0, 0, -3],
        mb: [5, null, null, 0],
        ...props.sx,
      }}
    >
      {links &&
        links.map((link) => (
          <NavLink to={link.page ? getLinkForPage(link.page) : link.url} key={link.id} darkMode={darkMode}>
            {link.title}
          </NavLink>
        ))}
    </Flex>
  );
};

export default Navigation;

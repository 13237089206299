/** @jsx jsx */
import axios from 'axios';
import { ErrorMessage, Field, Form, withFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, jsx } from 'theme-ui';
import * as yup from 'yup';
import { Spinner } from '.';
import { isBrowser } from '../utils';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import CheckboxField from './CheckboxField';
import FormField from './FormField';

const mailListURL = 'https://uutiskirje.matkahuolto.fi';

const guid = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const useMailerForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    if (!isBrowser) return [];
    if (isLoading || data || error) return [];
    (async () => {
      setLoading(true);
      if (process.env.NODE_ENV === 'development') {
        // CORS is not enabled for localhost:8000
        // curl "https://uutiskirje.matkahuolto.fi/json?_=h8xvi681r5g238cav6tt57"
        const response = {
          properties: [
            { text: 'email', name: 'email', required: true, label: 'S\u00e4hk\u00f6posti', position: 0 },
            { text: 'sms', name: 'sms', required: false, label: 'Matkapuhelinnumero', position: 0 },
          ],
          consents: [],
          lists: [{ id: 825955, name: 'Uutiskirjeen tilaajat', position: null, label: 'Uutiskirjeen tilaajat' }],
          csrf_token: 'a881ff6f465c987af75a98f4ec410b6ac322c325c0e3959ed8563b4effe55bd5',
        };
        setData(response);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.get(mailListURL + '/json?_=' + guid());
        setData(response.data);
      } catch (error) {
        setError(error.toString());
      }
      setLoading(false);
    })();
  }, [data, isLoading, error]);

  return [data, isLoading, error];
};

const MailListForm = ({ elements, values, handleSubmit, isSubmitting, setFieldValue, translate }) => {
  return (
    <Form sx={{ maxWidth: 640 }}>
      {elements.map((h, index) => {
        if ('lists' === h.type) {
          return (
            <Field
              component={CheckboxField}
              name="join"
              multiple={true}
              id={'' + h.id}
              label={h.label}
              value={'' + h.id}
              setFieldValue={setFieldValue}
            />
          );
        } else if ('consents' === h.type) {
          return (
            <Field
              component={CheckboxField}
              name="consent"
              multiple={true}
              id={'' + h.value}
              label={h.label}
              value={'' + h.value}
              setFieldValue={setFieldValue}
            />
          );
        } else {
          return <FormField label={h.label + (h.required ? '*' : '')} name={h.name} />;
        }
      })}
      <ErrorMessage name="submit">{(msg) => <div sx={{ color: 'red', mt: 2 }}>{msg}</div>}</ErrorMessage>
      <Box sx={{ mt: 3 }}>
        <Button sx={{ minWidth: '120px' }} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('emailList.submit')}
        </Button>
      </Box>
    </Form>
  );
};

const MailListFormik = withFormik({
  mapPropsToValues: ({ elements }) => {
    const obj = {
      join: [],
      consent: [],
      submit: '',
    };
    elements.forEach((el) => {
      if (el.type === 'properties' && el.name) {
        obj[el.name] = '';
      }
    });
    return obj;
  },
  validationSchema: ({ elements, translate }) => {
    const shape = {};
    let nConsents = 0;
    elements.forEach((el) => {
      if (el.type === 'properties' && el.name) {
        shape[el.name] = yup.string();
        if (el.required) {
          shape[el.name] = shape[el.name].required(translate('form.requiredField'));
        } else if (el.type === 'lists') {
          shape.join = yup
            .array()
            .of(yup.string())
            .min(1, translate('emailList.atleastOne'))
            .required(translate('form.requiredField'));
        } else if (el.type === 'consents') {
          nConsents += 1;
          shape.consent = yup
            .array()
            .of(yup.string())
            .min(nConsents, translate('emailList.consent'))
            .required(translate('form.requiredField'));
        }
      }
    });
    return yup.object().shape(shape);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'VerifyCodeFormik',
})(MailListForm);

export default (props) => {
  const { t: translate } = useTranslation();
  const [formData, isLoading, error] = useMailerForm();
  const [isSubmitted, setSubmitted] = useState(false);
  const elements = useMemo(() => {
    if (!formData) return [];
    var a = formData;
    var b = [];
    if (a.lists && 1 === a.lists.length) a.lists = [];
    for (var c in a) if (Array.isArray(a[c])) for (var d in a[c]) (a[c][d].type = c), b.push(a[c][d]);
    b = b.sort(function (a, b) {
      return a.position > b.position ? 1 : -1;
    });
    return b;
  }, [formData]);

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      const body = new URLSearchParams();
      body.append('cfcfcfcfcf', formData?.csrf_token);
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'submit') return;
        if (Array.isArray(value) && value.length === 0) return;
        body.append(key, value);
      });

      if (!body.has('join')) {
        const listId = 825955;
        body.append('join', listId);
      }

      /*
      const body = { ...values, cfcfcfcfcf: formData?.csrf_token };
      delete body.submit;
      if (body.join.length === 0) delete body.join;
      if (body.consent.length === 0) delete body.consent;
      */

      try {
        const response = await axios.post(mailListURL + '/account?ajax', body);
        const { success, error_msg } = response.data;
        if (success) {
          setSubmitted(true);
        } else if (error_msg) {
          setFieldError('submit', error_msg);
        } else {
          setFieldError('submit', translate('emailList.error'));
        }
      } catch (error) {
        setFieldError('submit', error.toString());
      }
    },
    [formData, translate]
  );

  if (isLoading || !isBrowser) {
    return (
      <div sx={{ position: 'relative', height: '100px', maxWidth: 640 }}>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <Box>{error}</Box>;
  }

  if (isSubmitted) {
    return <Box sx={{ maxWidth: 640, ml: 4 }}>{translate('emailList.success')}</Box>;
  }

  return (
    <Box sx={{ maxWidth: 640 }}>
      <MailListFormik elements={elements} onSubmit={onSubmit} translate={translate} />
    </Box>
  );
};

/*
curl -v "https://uutiskirje.matkahuolto.fi/account?ajax" -H 'Content-Type: application/json' -d '{"sms":"","email":"leppakorpitcgi@gmail.com","cfcfcfcfcf":"5305bee2c4ff2c0aca0efcc40b07a3a4d1004ac6d29cdd9c7726d1e22149ce17"}'

response: 200
{"success":false,"error_key":"","error_msg":"","account_url":""}
*/

/* Dynamic form code from LianaMailer console:

document.addEventListener('DOMContentLoaded', function () {
  try {
    var LianaMailer = {
      ajax: function (a, b, c, d, e) {
        var f;
        if ('undefined' != typeof XMLHttpRequest) f = new XMLHttpRequest();
        else
          for (
            var g = [
                'MSXML2.XmlHttp.5.0',
                'MSXML2.XmlHttp.4.0',
                'MSXML2.XmlHttp.3.0',
                'MSXML2.XmlHttp.2.0',
                'Microsoft.XmlHttp',
              ],
              h = 0,
              i = g.length;
            h < i;
            h++
          )
            try {
              f = new ActiveXObject(g[h]);
              break;
            } catch (a) {}
        (f.onreadystatechange = function () {
          if (!(f.readyState < 4))
            if (4 === f.readyState)
              if (200 === f.status) d(f);
              else e();
        }),
          f.open(b, a, !0),
          f.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded'),
          f.send(c);
      },
      serialize: function (a) {
        for (var b = [], c = 0; c < a.elements.length; c++) {
          var d = a.elements[c];
          if (
            d.name &&
            !d.disabled &&
            'file' !== d.type &&
            'reset' !== d.type &&
            'submit' !== d.type &&
            'button' !== d.type
          ) {
            if (('checkbox' !== d.type && 'radio' !== d.type) || d.checked)
              b.push(encodeURIComponent(d.name) + '=' + encodeURIComponent(d.value));
          } else;
        }
        return b.join('&');
      },
      guid: function () {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      },
      setAttributes: function (a, b) {
        for (var c in b) a.setAttribute(c, b[c]);
      },
      showError: function (a) {
        var b = this.form.querySelector('[data-lianamailer="formfield-error"]');
        if (!a) a = 'Tapahtui virhe - Toimintoa ei voitu suorittaa loppuun.';
        if (b) (b.textContent = a), b.focus();
        else
          (b = document.createElement('div')),
            (b.textContent = a),
            this.setAttributes(b, {
              class: 'lianamailer-formfield lianamailer-formfield-error',
              'data-lianamailer': 'formfield-error',
              tabindex: '-1',
            }),
            (b.style.outline = 'none'),
            (b.style.display = 'block'),
            this.form.insertBefore(b, this.form.childNodes[0]),
            b.focus();
      },
      showSuccess: function () {
        for (var a = this.form.querySelectorAll('[data-lianamailer^=formfield]'), b = 0; b < a.length; b++)
          a[b].style.display = 'none';
        var c = this.form.querySelector('[data-lianamailer="success"]');
        c.style.display = 'block';
      },
      success_message: 'Kiitos tilauksestasi',
      btn_text: 'Tilaa',
      form_wrapper: '[data-lianamailer="form-wrapper"]',
      getFormFields: function (a) {
        var b = [];
        if (a.lists && 1 === a.lists.length) a.lists = [];
        for (var c in a) if (Array.isArray(a[c])) for (var d in a[c]) (a[c][d].type = c), b.push(a[c][d]);
        b = b.sort(function (a, b) {
          return a.position > b.position ? 1 : -1;
        });
        for (var e = [], f = this, g = 0; g < b.length; g++) {
          var h = b[g],
            i = document.createElement('div');
          if (
            (f.setAttributes(i, {
              class: 'lianamailer-formfield lianamailer-formfield-' + h.type,
              'data-lianamailer': 'formfield',
            }),
            'lists' === h.type || 'consents' === h.type || 'properties' === h.type)
          ) {
            var j = document.createElement('label');
            if ('lists' === h.type) {
              var k = document.createElement('input');
              f.setAttributes(k, { type: 'checkbox', name: 'join[]', value: h.id });
              var l = document.createElement('span');
              (l.innerText = h.label),
                j.appendChild(k),
                j.appendChild(document.createTextNode(' ')),
                j.appendChild(l),
                i.appendChild(j),
                e.push(i);
            } else if ('consents' === h.type) {
              var k = document.createElement('input');
              f.setAttributes(k, { type: 'checkbox', name: 'consent[]', value: h.value });
              var l = document.createElement('span');
              (l.innerText = h.description),
                j.appendChild(k),
                j.appendChild(document.createTextNode(' ')),
                j.appendChild(l),
                i.appendChild(j),
                e.push(i);
            } else {
              j.innerText = h.label + (h.required ? '*' : '');
              var m = document.createElement('div');
              m.setAttribute('class', 'lianamailer-formfield-inner');
              var k = document.createElement('input');
              if (
                (f.setAttributes(k, { type: 'email' === h.name ? 'email' : 'text', name: h.name, value: '' }),
                h.required)
              )
                k.setAttribute('required', 'required');
              i.appendChild(j), m.appendChild(k), i.appendChild(m), e.push(i);
            }
          }
        }
        return e;
      },
      generateHTML: function (a) {
        var b = document.createElement('form');
        this.setAttributes(b, { class: 'lianamailer', 'data-lianamailer': 'form', method: 'post', action: '' });
        for (var c = this.getFormFields(a), d = 0; d < c.length; d++) b.appendChild(c[d]);
        var e = document.createElement('div');
        (e.style.position = 'absolute'), (e.style.left = '-7777px'), e.setAttribute('aria-hidden', 'true');
        var f = document.createElement('input');
        this.setAttributes(f, { type: 'text', name: 'lm-gtfo', tabindex: '-1' }), e.appendChild(f), b.appendChild(e);
        var g = document.createElement('div');
        if (
          (this.setAttributes(g, { class: 'lianamailer-success', 'data-lianamailer': 'success' }),
          'string' == typeof this.success_message_html)
        )
          g.innerHTML = this.success_message_html;
        else g.textContent = this.success_message;
        (g.style.display = 'none'), b.appendChild(g);
        var h = document.createElement('div');
        this.setAttributes(h, {
          class: 'lianamailer-formfield lianamailer-formfield-submit',
          'data-lianamailer': 'formfield-submit',
        });
        var i = document.createElement('button');
        return (
          this.setAttributes(i, { type: 'submit', 'data-lianamailer': 'submit' }),
          (i.innerText = this.btn_text),
          h.appendChild(i),
          b.appendChild(h),
          b
        );
      },
      init: function (a) {
        var b = this;
        if (a) {
          if (a.form_wrapper) b.form_wrapper = a.form_wrapper;
          if (a.btn_text) b.btn_text = a.btn_text;
          if (a.success_message) b.success_message = a.success_message;
          if (a.success_message_html) b.success_message_html = a.success_message_html;
        }
        b.ajax(
          'https://uutiskirje.matkahuolto.fi/json?_=' + b.guid(),
          'get',
          '',
          function (a) {
            var c = JSON.parse(a.responseText),
              d = document.querySelector(b.form_wrapper);
            if (null === d) return void console.error("LianaMailer can't find element:", b.form_wrapper);
            else
              return (
                (b.form = b.generateHTML(c)),
                b.form.setAttribute('data-lianamailer-token', c.csrf_token),
                d.appendChild(b.form),
                void b.form.addEventListener('submit', function (a) {
                  a.preventDefault();
                  var d = document.querySelector('[data-lianamailer="submit"]');
                  (d.disabled = !0),
                    (c = b.serialize(b.form) + '&cfcfcfcfcf=' + b.form.getAttribute('data-lianamailer-token')),
                    b.ajax(
                      'https://uutiskirje.matkahuolto.fi/account?ajax',
                      'post',
                      c,
                      function (a) {
                        var c = JSON.parse(a.responseText);
                        if (c.success) b.showSuccess();
                        else b.showError(c.error_msg);
                        d.disabled = !1;
                      },
                      function () {
                        b.showError(), (d.disabled = !1);
                      }
                    );
                })
              );
          },
          function () {
            b.showError();
          }
        );
      },
    };
    var lianamailer = Object.create(LianaMailer);
    lianamailer.init();
  } catch (e) {
    console.error(e);
  }
});
*/

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findTrackingInfo } from '../state/tracking';
import TrackingParcel from '../components/TrackingParcel';
import Spinner from '../components/Spinner';
import { isBrowser } from '../utils';
import TrackingSearch from '../components/TrackingSearch';
import 'url-search-params-polyfill'; // for IE
import * as analytics from '../utils/analytics';
import { graphql } from 'gatsby';

// eslint-disable-next-line react/display-name
const searchByQuery = ComponentToWrap => ({ pageContext, location, inlineTracking }) => {
  const { fetching, parcelNumber: alreadyFetched, language } = useSelector(state => state.tracking);
  const { locale } = pageContext || {};

  const dispatch = useDispatch();
  const queryParcelNumber = isBrowser && location && new URLSearchParams(location.search).get('parcelNumber');
  useEffect(() => {
    if (
      !fetching &&
      queryParcelNumber &&
      pageContext &&
      (alreadyFetched !== queryParcelNumber || pageContext.locale != language)
    ) {
      dispatch(findTrackingInfo(queryParcelNumber, inlineTracking));
    }
  }, [dispatch, fetching, queryParcelNumber, alreadyFetched, locale]);

  return <ComponentToWrap parcelNumber={queryParcelNumber} pageContext={pageContext} inlineTracking={inlineTracking} />;
};

function TrackShipment({ parcelNumber, inlineTracking }) {
  analytics.usePageCategory('paketit');
  const trackingState = useSelector(state => state.tracking);

  return (
    <Box>
      <div sx={{ position: 'relative', width: inlineTracking ? ['100%', null, '67%'] : '100%' }}>
        <TrackingSearch input={parcelNumber} inlineTracking={inlineTracking} />
        {trackingState.parcel && <TrackingParcel parcel={trackingState.parcel} />}
      </div>
    </Box>
  );
}

export const TrackByQuery = searchByQuery(TrackShipment);
export const TrackBySearch = TrackShipment;

import i18n from '../localization/i18n';
import * as api from '../utils/api';
import { showNotification, SEVERITY } from './notifications';

export const xxsDeliveryEnabled = true;

export const SizeCode = {
  BOARD: 'Taulu',
  BIKE: 'Polkupyörä',
  TIRESET: 'Rengassetti',
  SKIBAG: 'Suksipussi',
  REPACK: 'RePack',
  REFILL: 'RePack-ReFill',
  XXLFlex: 'XXL Flex',
};

export const ServiceCode = {
  Delivery8_16: 'JA',
  DeliveryAgreedTime: 'KJ',
  DeliveryToMailBox: 'JPL',
  HandleWithCare: 'EK',
  PickUp: 'NO',
  Heavy5kg: 'P5',
  HeavyWithDelivery: 'PP',
  NoDelivery: 'NMH', // delivery method option
};

export const BIKE_PRODUCT_ID = '11Polkupyörä35';
export const TIRESET_PRODUCT_ID =
  process.env.GATSBY_ACTIVE_ENV === 'production' ? '13Rengassetti120' : '13Rengassetti55';
export const REPACK_PRODUCT_ID = '86RePack30';
export const REFILL_PRODUCT_ID = '86RePack-ReFill30';
export const DELIVERY_START_PRICE = 10.9;
export const STORAGE_TIME_START_PRICE = 0.0;
export const MULTIPARCEL_QUANTITY = 10;

export const multiParcelProduct = (product) => {
  const response = {
    ...product,
    quantity: MULTIPARCEL_QUANTITY,
    price: +(MULTIPARCEL_QUANTITY * product.price).toFixed(2),
    priceVat0: +(MULTIPARCEL_QUANTITY * product.priceVat0).toFixed(2),
  };

  const dbPricing = (product.multiPrice || {})[MULTIPARCEL_QUANTITY];
  if (dbPricing) {
    response.price = dbPricing.price;
    response.priceVat0 = dbPricing.priceVat0;
  }

  return response;
};

export const imagePathForProduct = (productId) => {
  const defaultImage = '/box.svg';
  if (/Rengassetti/.test(productId)) {
    return '/tire.svg';
  } else if (/Polkupyörä/.test(productId)) {
    return '/bicycle.svg';
  } else if (/Suksipussi/.test(productId)) {
    return '/ski.svg';
  }

  const idRegex = /^(.._)?([0-9]{2}K?)([^0-9]*)([0-9]*)$/;
  const [all, countryCode, serviceCode, sizeCode, weightClass] = idRegex.exec(productId) || [];

  const img =
    {
      '86XXS30': '/box-xxs.svg',
      '86XS30': '/box-xs.svg',
      '86S30': '/box-s.svg',
      '11S55': '/box-s.svg',
      '86SMaxi30': '/box-s-maxi.svg',
      '11SMaxi55': '/box-s-maxi.svg',
      '86M30': '/box-m.svg',
      '11M55': '/box-m.svg',
      '86MMaxi30': '/box-m-maxi.svg',
      '11MMaxi55': '/box-m-maxi.svg',
      '86L30': '/box-l.svg',
      '11L55': '/box-l.svg',
      '86LMaxi30': '/box-l-maxi.svg',
      '11LMaxi55': '/box-l-maxi.svg',
      '86XL30': '/box-xl.svg',
      '11XL55': '/box-xl.svg',
      '86XLMaxi30': '/box-xl-maxi.svg',
      '11XLMaxi55': '/box-xl-maxi.svg',
      '11XXL30': '/box-xxl.svg',
      '11XXL55': '/box-xxl.svg',
      '86XLLeveä30': '/box-xl-wide.svg',
      '11XXLPitkä30': '/box-xxl-long.svg',
      '11XXLPitkä55': '/box-xxl-long.svg',
      '11XXLLeveä30': '/box-xxl-wide.svg',
      '11XXLLeveä55': '/box-xxl-wide.svg',
      '86RePack30': '/repack.svg',
      '86RePack-ReFill30': '/repack.svg',
      '86Taulu10': '/painting.svg',

      XXS: '/box-xxs.svg',
      XS: '/box-xs.svg',
      S: '/box-s.svg',
      SMaxi: '/box-s-maxi.svg',
      SMaxiPitkä: '/box-s-maxi-long.svg',
      M: '/box-m.svg',
      MMaxi: '/box-m-maxi.svg',
      MMaxiPitkä: '/box-m-maxi-long.svg',
      L: '/box-l.svg',
      LMaxi: '/box-l-maxi.svg',
      LMaxiPitkä: '/box-l-maxi-long.svg',
      XL: '/box-xl.svg',
      XLMaxi: '/box-xl-maxi.svg',
      XLLeveä: '/box-xl-wide.svg',
      XXLFlex: '/box-xxl.svg',
      XXL: '/box-xxl.svg', // 1.4.2022 removed
      XXLPitkä: '/box-xxl-long.svg', // 1.4.2022 removed
      XXLLeveä: '/box-xxl-wide.svg', // 1.4.2022 removed
      RePack: '/repack.svg',
      'RePack-ReFill': '/repack.svg',
      Taulu: '/painting.svg',
      weight: '/weight.svg',
      automat: '/automat.svg',
      boxxs2: '/Box-xs2.svg',
    }[sizeCode || productId?.replace(/\s+/g, '')] || defaultImage;
  return img;
};

const initialState = {
  products: [],
  country: {},
};

export const requiresMHorAgentToSend = (product, services) => {
  if ((product.isHeavy && (product.isLarge || product.weightClass > 35)) || product.isExtraLarge) {
    return true;
  }
  if (product.sizeCode === SizeCode.REPACK) {
    return true;
  }
  // Handle-with-care service, does not affect pickup point, only send-location
  return (services || []).some((s) => s.serviceCode === 'EK');
};

export const weightText4product = (prod, translate, basket = false, services) => {
  if (prod.sizeCode === SizeCode.SKIBAG) {
    return `, ${translate('buyParcel.product.under')} 10 kg`;
  }
  if (isSpecial(prod)) {
    return '';
  }
  if (basket) {
    if (services && services.some((s) => s.serviceCode === ServiceCode.DeliveryToMailBox)) {
      return ` / 1 kg`;
    } else {
      return ` / ${prod.weightClass} kg`;
    }
  } else {
    return `, ${translate('buyParcel.product.under')} ${prod.weightClass} kg`;
  }
  // return `, 30 - ${prod.weightClass} kg`;
};

const isSpecial = (product) => {
  return 1 + [SizeCode.BIKE, SizeCode.TIRESET, SizeCode.SKIBAG].indexOf(product.sizeCode); // 0 if not found
};

const specialOrder = (product) => {
  return (
    1 +
    [SizeCode.REPACK, SizeCode.REFILL, SizeCode.BOARD, SizeCode.BIKE, SizeCode.TIRESET, SizeCode.SKIBAG].indexOf(
      product.sizeCode
    )
  ); // 0 if not found
};

const sortProducts = (products) => {
  let sortedProducts = products.sort((a, b) => {
    const aSpecial = specialOrder(a);
    const bSpecial = specialOrder(b);
    if (aSpecial || bSpecial) {
      return aSpecial - bSpecial;
    }
    if (a.price !== b.price) {
      return a.price - b.price;
    } else {
      return a.order - b.order;
      // return maxDimension(a) - maxDimension(b);
    }
  });

  const mailboxProductIdx = sortedProducts.findIndex((product) => product?.mailboxDelivery);
  if (mailboxProductIdx !== -1) {
    const mailboxProduct = sortedProducts.splice(mailboxProductIdx, 1)[0];
    sortedProducts.splice(1, 0, mailboxProduct);
  }
  return sortedProducts;
};

let isFetchingProducts = undefined;
export const areProductsLoaded = () => {
  return isFetchingProducts === null;
};

export const getProducts = () => async (dispatch) => {
  if (isFetchingProducts) {
    return await isFetchingProducts;
  }

  isFetchingProducts = (async () => {
    try {
      return await api.getProducts();
    } catch (error) {
      console.warn('Failed to load products:', error);
      return [];
    }
  })();

  const localCopy = isFetchingProducts;
  const products = await isFetchingProducts;
  if (isFetchingProducts === localCopy) {
    isFetchingProducts = null;
  }
  dispatch(setProducts(sortProducts(products)));
  return products;
};

export const loadProductsForCountry = (countryCode, resetProductSelection) => async (dispatch, getState) => {
  const { country } = getState().catalog;
  const language = i18n.language;
  if (resetProductSelection) {
    dispatch({
      type: SET_COUNTRY,
      countryCode,
    });
  }
  if (countryCode === 'FI' || country[countryCode]) {
    return;
  }
  try {
    isFetchingProducts = api.getProductsForCountry(countryCode, language);
    const localCopy = isFetchingProducts;
    const { products } = await isFetchingProducts;
    if (isFetchingProducts !== localCopy) {
      return;
    }
    isFetchingProducts = null;
    dispatch({
      type: SET_COUNTRY_PRODUCTS,
      countryCode,
      products,
    });
    return products;
  } catch (error) {
    isFetchingProducts = null;
    return dispatch(showNotification('genericApiError', SEVERITY.ERROR));
  }
};

const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_COUNTRY_PRODUCTS = 'SET_COUNTRY_PRODUCTS';

export const setProducts = (products) => ({
  type: SET_PRODUCTS,
  products,
});

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case SET_PRODUCTS:
      return { ...state, ...params };
    case SET_COUNTRY_PRODUCTS:
      const { products, countryCode, ...rest } = params;
      return {
        ...state,
        country: { ...state.country, [countryCode]: products },
        ...rest,
      };
    default:
      return state;
  }
};

export const productCode2Name = {
  10: 'Bussipaketti',
  11: 'Bussipaketti', // (käteinen)
  '11S10': 'Bussipaketti', // - 10:n sarja
  13: 'Rengassetti', // (käteinen)
  '13S10': 'Rengassetti', // - 10:n sarja
  20: 'Pikapaketti',
  21: 'Pikapaketti', // (käteinen)
  24: 'Pikapaketti Plus',
  30: 'Jakopaketti',
  '30S': 'Siirto jakopakettina',
  34: 'Kotijakelu',
  '34S': 'Siirto kotijakeluna',
  35: 'Kotijakelu', // (käteinen)
  '35S': 'Siirto kotijakeluna',
  36: 'Rengassetin kotijakelu', // (käteinen)
  // '37': '???',
  51: 'Noutopaketti',
  52: 'Kuriiripaketti',
  53: 'Noutopaketin palautus',
  57: 'Lavarahti',
  60: 'Ärräpaketti',
  61: 'Mini-Ärräpaketti',
  62: 'Ärräpussi',
  64: 'Ärräpaketin palautus',
  65: 'R-avain', // nouto samasta kioskista
  66: 'R-avain', // kuljetus toiseen kioskiin
  67: 'R-drop-point',
  70: 'Ulkomaan lentoasiakirja',
  '70K': 'Ulkomaan lentoasiakirja',
  71: 'Ulkomaan lentopaketti',
  '71K': 'Ulkomaan lentopaketti',
  72: 'Ulkomaan paketti',
  80: 'Lähellä-paketti',
  81: 'Asiakaspalautus',
  83: 'Noutamattoman palautus',
  84: 'XXS',
  86: 'Lähi-paketti', // (käteinen)
  '86S05': 'Lähi-paketti', // - 5:n sarja
  '86S10': 'Lähi-paketti', // - 10:n sarja
  95: 'Ulkomaan verkkopaketti',
  '95K': 'Ulkomaan Lähellä-paketti',
  96: 'Ulkomaan jakopaketti',
  '96K': 'Ulkomaan jakopaketti',
  97: 'Ulkomaan kotijakelu',
  '97K': 'Ulkomaan kotijakelu',
};

/* moved to server side
const domesticDayTimeDelivery = ['30', '30S'];
const domesticHomeDelivery = ['34', '34S', '35', '35S', '36', '37', '57'];
const abroadHomeDelivery = ['70', '70K', '71', '71K', '72', '96', '96K', '97', '97K'];
const allHomeDelivery = [...domesticDayTimeDelivery, ...domesticHomeDelivery, ...abroadHomeDelivery];

export const hasHomeDelivery = ({ productCode, additionalServices, shipmentStatus }) => {
  return (
    ((additionalServices || []).some(s => ['JA', 'KJ', 'JPL'].includes(s.additionalServiceCode)) ||
      allHomeDelivery.includes(productCode)) &&
    !isShipmentWaitingRecipientPickup(shipmentStatus, productCode, additionalServices)
  );
};

export const isShipmentWaitingRecipientPickup = (shipmentStatus, productCode, additionalServices) => {
  if (abroadHomeDelivery.includes(productCode)) {
    return false;
  }
  if (
    domesticHomeDelivery.includes(productCode) ||
    (additionalServices || []).some(s => s.additionalServiceCode === 'KJ')
  ) {
    // kotijakelu
    if (['55', '56'].includes(shipmentStatus)) {
      // first or second text message
      return false;
    }
  }
  if (['55', '56', '57'].includes(shipmentStatus)) {
    return true;
  }
  return false;
};
*/

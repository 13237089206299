import React from 'react';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import translatePath from '../utils/translatePath';
import { useTranslation } from 'react-i18next';

const translateName = (children, to, locale, data) => {
  if (!to || !locale) return children;
  if (data.allSitePage && data.allSitePage.nodes) {
    const node = data.allSitePage.nodes.find(
      (node) => node?.pageContext?.paths && Object.values(node?.pageContext?.paths)?.includes(to)
    );
    if (node && node.pageContext && node.pageContext.titles && node.pageContext.titles[locale]) {
      return node.pageContext.titles[locale];
    }
  }
  // fallback
  return children;
};

const Link = ({ data, children, to, noTitleFix, noPathTranslation, ...other }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  let href = '/';
  let external = false;

  if (typeof to === 'object' && to !== null) {
    href = to.link;
    external = to.external;
  } else if (typeof to === 'string') {
    href = to;
  }

  if (href.startsWith('/') && !external && !other.target) {
    return (
      <GatsbyLink {...other} to={noPathTranslation ? to : translatePath(href, locale, data)}>
        {noTitleFix ? children : translateName(children, href, locale, data)}
      </GatsbyLink>
    );
  }
  return (
    <a href={href} {...other}>
      {children}
    </a>
  );
};

export const Link2 = (props) => {
  return <StaticQueryLink {...props} noTitleFix />;
};

const StaticQueryLink = (props) => {
  const data = useStaticQuery(graphql`
    query AllSitePageLinkQuery {
      allSitePage {
        nodes {
          pageContext
        }
      }
    }
  `);
  return <Link data={data} {...props} />;
};

export default StaticQueryLink;

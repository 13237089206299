/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { Button } from '../';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { buyParcel, buySeriesShipment } from '../../state/parcelFlow';
import { useTranslation } from 'react-i18next';
import { imagePathForProduct } from '../../state/catalog';
import { formatPrice } from '../../utils/formatters';
import moment from 'moment-timezone';

export default ({ name: productName, price, maxSize, productId, countryCode, productType, productData }) => {
  const isSeriesProduct = productType === 'Sarjapaketti';
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    !isSeriesProduct
      ? dispatch(buyParcel(productId, countryCode || 'FI'))
      : dispatch(buySeriesShipment(true, productId, countryCode || 'FI'));
  }, [dispatch, productId, countryCode, isSeriesProduct]);
  const { t: translate, i18n } = useTranslation();
  const locale = i18n.language;

  const product = useMemo(() => {
    return productData?.find((p) => p.id === productId);
  }, [productData, productId]);

  const specialOffer = useMemo(() => {
    if (!product?.specialOffer) return null;

    const { specialOffer } = product;
    const now = moment();

    const isValidParcelType = isSeriesProduct === (specialOffer.parcelType === 'multiParcel');
    const isActive = now.isBetween(specialOffer.startTime, specialOffer.endTime);

    return isValidParcelType && isActive ? specialOffer : null;
  }, [product, isSeriesProduct]);

  return (
    <Flex
      onClick={onClick}
      role="button"
      sx={{
        borderRadius: 2,
        border: '2px solid',
        borderColor: 'border',
        flexDirection: 'column',
        width: '100%',
        cursor: 'pointer',
        ':hover': {
          borderColor: 'grayLight',
        },
        maxWidth: [null, null, null, 164],
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          display: ['none', null, null, 'flex'],
          bg: specialOffer ? specialOffer?.color || 'primary' : null,
          flexDirection: 'column',
          color: 'white',
          borderRadius: 1,
          fontSize: '11px',
          alignSelf: 'start',
          minHeight: '26px',
          mx: 1,
          mt: 1,
          py: 1,
          px: 2,
          justifyContent: 'center',
        }}
      >
        <span sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          {specialOffer && specialOffer?.text?.[locale || 'fi']}
        </span>
      </Flex>

      <Flex
        sx={{
          height: '100%',
          px: 3,
          pt: [4, null, null, 0],
          pb: [4, null, null, 3],
          flexDirection: ['row', null, null, 'column'],
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flex: 1,
            mt: [-3, null, null, 0],
            ml: ['-11px', null, null, 0],
          }}
        >
          {specialOffer && (
            <div
              sx={{
                display: ['inline-block', null, null, 'none'],
                alignSelf: 'flex-start',
                bg: specialOffer?.color || 'primary',
                color: 'white',
                borderRadius: 1,
                py: 1,
                px: 2,
                fontSize: '11px',
                position: 'absolute',
                top: '5px',
              }}
            >
              {specialOffer?.text?.[locale || 'fi']}
            </div>
          )}
          <img
            src={imagePathForProduct(productId)}
            alt={productName}
            width="60"
            height="60"
            sx={{ mt: !specialOffer ? [2, null, null, 0] : 0 }}
          />
        </Flex>

        <Flex
          sx={{
            flex: 2,
            width: [null, null, null, 130],
            flexDirection: 'column',
            alignItems: 'center',
            mx: 3,
            gap: 2,
          }}
        >
          <span
            sx={{
              fontSize: 18,
              fontWeight: 'medium',
              textAlign: 'center',
              wordWrap: 'break-word',
            }}
          >
            {isSeriesProduct ? (
              <>
                {translate('buyParcel.product.seriesShipment')}
                <br />
                {productName}
              </>
            ) : (
              productName
            )}
          </span>
          <span sx={{ color: 'black', fontSize: 14, textAlign: 'center' }}>
            {productId === '11Polkupyörä35' ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('buyParcel.product.bikeSize'),
                }}
              />
            ) : (
              <>
                {translate('buyParcel.product.maxSize')}
                <br />
                {maxSize}
              </>
            )}
          </span>
        </Flex>

        <Flex
          sx={{
            display: 'flex',
            flex: [1, null, null, 0],

            mt: [0, null, null, 3],
            justifyContent: ['flex-end', null, null, null],
            minWidth: [80, null, null, 0],
          }}
        >
          <Button variant="small" onClick={onClick}>
            {formatPrice(price)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

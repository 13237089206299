import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import translatePath from '../utils/translatePath';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const LocalizedButton = ({ to, ...props }) => {
  const data = useStaticQuery(graphql`
    query AllSitePageButtonLinkQuery {
      allSitePage {
        nodes {
          pageContext
        }
      }
    }
  `);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const locTo = translatePath(to, locale, data);
  return <Button to={locTo} {...props} />;
};

export default LocalizedButton;

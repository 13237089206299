import pick from 'lodash/pick';
import * as api from '../utils/api';

import { showNotification } from './notifications';
import i18n from '../localization/i18n';

export const PAGE = {
  SELECT_ADDRESS: 1,
  SELECT_TIME: 2,
  ADD_DETAILS: 3,
  PAYMENT: 4,
};

const initialState = {
  page: PAGE.SELECT_ADDRESS,
  address: null,
  services: [],
  time: null,
  details: null,
  discount: null,
};

const RESET_DELIVERY_FLOW = 'RESET_DELIVERY_FLOW';
const SELECT_DELIVERY_PAGE = 'SELECT_DELIVERY_PAGE';
const SAVE_ADDRESS = 'SAVE_ADDRESS';
const SAVE_DELIVERY_TIME = 'SAVE_DELIVERY_TIME';
const SAVE_DELIVERY_DETAILS = 'SAVE_DELIVERY_DETAILS';

export const resetDeliveryFlow = () => ({
  type: RESET_DELIVERY_FLOW,
});

export const goBack = () => async (dispatch, getState) => {
  const state = getState().delivery;
  let prevPage = state.page - 1;
  if (state.page === PAGE.CONFIRMATION) {
    prevPage = PAGE.SELECT_TIME;
  }

  dispatch({
    type: SELECT_DELIVERY_PAGE,
    page: prevPage,
  });
};

export const goNext = () => async (dispatch, getState) => {
  const state = getState().delivery;
  const { page } = state;
  let nextPage = page + 1;

  dispatch({
    type: SELECT_DELIVERY_PAGE,
    page: nextPage,
  });
};

export const saveAddressAndServices = (values, services) => {
  return {
    type: SAVE_ADDRESS,
    address: pick(values, ['street', 'apartment', 'postcode', 'city', 'phoneNumber']),
    services,
  };
};

export const selectDeliveryTime = (time) => async (dispatch, getState) => {
  dispatch({
    type: SAVE_DELIVERY_TIME,
    time,
    discount: null,
  });

  dispatch({
    type: SELECT_DELIVERY_PAGE,
    page: PAGE.ADD_DETAILS,
  });
};

export const getDeliveryPrice = (state) => {
  const code = state.delivery.time;
  const service = state.delivery.services.find((s) => s.serviceCode === code);
  const addService = service && (service.additionalServices || [])[0];
  return (addService || {}).priceVatIncluded || 0;
};

export const saveDiscount = (discount) => async (dispatch, getState) => {
  dispatch({
    type: SAVE_DELIVERY_DETAILS,
    discount,
  });
};

export const saveDeliveryDetails =
  ({ code, discount, ...details }, goNextPage = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: SAVE_DELIVERY_DETAILS,
      discount,
      details,
    });
    if (goNextPage) {
      dispatch({
        type: SELECT_DELIVERY_PAGE,
        page: PAGE.PAYMENT,
      });
    }
  };

export const createOrder = (shipmentNumber, paymentOptions) => async (dispatch, getState) => {
  try {
    const {
      address: { phoneNumber, ...address },
      time,
      details,
      discount,
    } = getState().delivery;
    const language = i18n.language;
    const response = await api.createDeliveryOrder(shipmentNumber, paymentOptions, language, {
      address,
      phoneNumber,
      time,
      details,
      discount,
    });
    // const { redirectUrl, orderId } = response;
    return response;
  } catch (error) {
    console.warn('Failed to create delivery order:', error);
    dispatch(showNotification('updateFailed'));
  }
};

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case RESET_DELIVERY_FLOW:
      return { ...initialState };
    case SELECT_DELIVERY_PAGE:
      return { ...state, ...params };
    case SAVE_ADDRESS:
      return { ...state, ...params };
    case SAVE_DELIVERY_TIME:
      return { ...state, ...params };
    case SAVE_DELIVERY_DETAILS:
      return { ...state, ...params };
    default:
      return state;
  }
};

export default (to, locale, data) => {
  if (!to || !locale) return to;
  let postfix = '';
  const postfixIndex = to.indexOf('?');
  if (postfixIndex !== -1) {
    postfix = to.substring(postfixIndex);
    to = to.substring(0, postfixIndex);
  }
  if (data.allSitePage && data.allSitePage.nodes) {
    const node = data.allSitePage.nodes.find(
      (node) => node?.pageContext?.paths && Object.values(node?.pageContext?.paths)?.includes(to)
    );
    if (node && node.pageContext && node.pageContext.paths && node.pageContext.paths[locale]) {
      return node.pageContext.paths[locale] + postfix;
    }
  }
  // fallback
  return to + postfix;
};

import * as api from '../utils/api';
import { showNotification, SEVERITY } from './notifications';
import { cloneDeep } from 'lodash';
import i18n from '../localization/i18n';

const initialState = {
  servicePoints: [],
  servicePointSearchResults: [],
  services: [],
  viewedAreas: [],
  selected: null,
  fetching: false,
  language: null,
  focus: null,
};

const SET_SERVICE_POINTS = 'SET_SERVICE_POINTS';
const SET_SERVICE_POINT_SERVICES = 'SET_SERVICE_POINT_SERVICES';
const SET_SELECTED_SERVICE_POINT = 'SET_SELECTED_SERVICE_POINT';
const FETCHING_SERVICE_POINTS_START = 'FETCHING_SERVICE_POINTS_START';
const FETCHING_SERVICE_POINTS_END = 'FETCHING_SERVICE_POINTS_END';
const FETCHING_SERVICE_POINTS_ERROR = 'FETCHING_SERVICE_POINTS_ERROR';
const SET_VIEWED_AREAS = 'SET_VIEWED_AREAS';
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
const SET_MAP_FOCUS = 'SET_MAP_FOCUS';
const UNSET_MAP_FOCUS = 'UNSET_MAP_FOCUS';

export const MAP_PRESELECTED_SERVICES = ['P1', 'P2', 'P3', 'P4', 'P35', 'VAK', 'LQ', 'EP', 'TERMINAL', 'SL', 'ES'];

export const getServicePointsFromArea =
  ({ left, right, top, bottom }) =>
  async (dispatch) => {
    //dispatch({ type: FETCHING_SERVICE_POINTS_START });
    const results = await api.getServicePointsFromArea(left, right, top, bottom);
    if (!results) {
      dispatch({ type: FETCHING_SERVICE_POINTS_ERROR });
      return dispatch(showNotification('genericApiError', SEVERITY.ERROR));
    } else if (results.length === 0) {
      dispatch({ type: FETCHING_SERVICE_POINTS_END });
      console.warn('No service points found from area');
    }
    dispatch(updateServicePoints(results));
  };

export const getServicePoints = (postalCode, streetAddress, serviceCodes, id) => async (dispatch, _getState) => {
  dispatch({ type: FETCHING_SERVICE_POINTS_START });
  const language = i18n.language;

  const servicePoints = await (id
    ? api.getServicePointById(id, serviceCodes, language)
    : api.getServicePoints(postalCode, streetAddress, serviceCodes, language));
  if (!servicePoints) {
    dispatch({ type: FETCHING_SERVICE_POINTS_ERROR });
    return dispatch(showNotification('genericApiError', SEVERITY.ERROR));
  } else if (servicePoints.length === 0) {
    dispatch({ type: FETCHING_SERVICE_POINTS_ERROR });
    return dispatch(showNotification('servicePoints.notFound', SEVERITY.WARN));
  }
  dispatch({ type: SET_SEARCH_RESULTS, servicePoints });
  dispatch(updateServicePoints(servicePoints));
};

const updateServicePoints = (newServicePoints) => (dispatch, getState) => {
  const newPoints = newServicePoints.map((p) => (p.distance ? { ...p, distance: undefined } : p));
  const currentPoints = cloneDeep(getState().servicePoints.servicePoints);
  const combinedServicePoints = currentPoints.concat(newPoints);
  const uniqueOfficeCodes = new Set();
  const servicePoints = combinedServicePoints.filter((s) => {
    if (uniqueOfficeCodes.has(s.officeCode)) {
      return false;
    }
    uniqueOfficeCodes.add(s.officeCode);
    return true;
  });
  dispatch({ type: SET_SERVICE_POINTS, servicePoints });
};

export const getServices =
  (preSelectedServices = MAP_PRESELECTED_SERVICES) =>
  async (dispatch, getState) => {
    if (!preSelectedServices || !preSelectedServices.length) {
      preSelectedServices = MAP_PRESELECTED_SERVICES;
    }
    const language = i18n.language;
    const services = await api.getServicePointServices(null, language);
    if (services) {
      services.unshift({ code: 'EP', description: i18n.t('servicePoints.onlyAutomats') });
      services.unshift({ code: '-EP', description: i18n.t('servicePoints.noAutomats') });
      services.unshift({ code: 'P35', description: i18n.t('servicePoints.mhAndAutomats') });
      services.forEach((service) => {
        service.selected = preSelectedServices.includes(service.code);
      });
      return dispatch({ type: SET_SERVICE_POINT_SERVICES, services, language });
    }
  };

export const toggleService = (code) => (dispatch, getState) => {
  const services = cloneDeep(getState().servicePoints.services);

  return dispatch({
    type: SET_SERVICE_POINT_SERVICES,
    services: services.map((service) =>
      service.code === code ? { ...service, selected: !service.selected } : service
    ),
  });
};

export const toggleMultipleServices = (codeMap) => (dispatch, getState) => {
  const services = cloneDeep(getState().servicePoints.services);

  return dispatch({
    type: SET_SERVICE_POINT_SERVICES,
    services: services.map((service) =>
      service.code in codeMap ? { ...service, selected: codeMap[service.code] } : service
    ),
  });
};

export const resetFilters = () => (dispatch, getState) => {
  const services = cloneDeep(getState().servicePoints.services);

  return dispatch({
    type: SET_SERVICE_POINT_SERVICES,
    services: services.map((service) =>
      MAP_PRESELECTED_SERVICES.includes(service.code) ? { ...service, selected: true } : { ...service, selected: false }
    ),
  });
};

export const setSelectedServicePoint = (servicePoint) => ({
  type: SET_SELECTED_SERVICE_POINT,
  servicePoint,
});

export const setViewedAreas = (viewedAreas) => ({
  type: SET_VIEWED_AREAS,
  viewedAreas,
});

export const setMapFocus = (lat, lng) => {
  if (lat && lng) {
    return {
      type: SET_MAP_FOCUS,
      lat,
      lng,
    };
  } else {
    return {
      type: UNSET_MAP_FOCUS,
    };
  }
};

export default (state = initialState, action) => {
  const { type, ...rest } = action;
  switch (type) {
    case SET_SERVICE_POINTS:
      return {
        ...state,
        servicePoints: action.servicePoints,
        fetching: false,
      };
    case SET_SEARCH_RESULTS:
      return { ...state, servicePointSearchResults: action.servicePoints };
    case SET_VIEWED_AREAS:
      return { ...state, viewedAreas: action.viewedAreas };
    case FETCHING_SERVICE_POINTS_START:
      return { ...state, fetching: true };
    case FETCHING_SERVICE_POINTS_END:
      return { ...state, fetching: false };
    case FETCHING_SERVICE_POINTS_ERROR:
      return { ...state, fetching: false };
    case SET_SELECTED_SERVICE_POINT:
      return { ...state, selected: action.servicePoint };
    case SET_SERVICE_POINT_SERVICES:
      return { ...state, services: action.services, language: action.language || state.language };
    case SET_MAP_FOCUS:
      return { ...state, focus: rest };
    case UNSET_MAP_FOCUS:
      return { ...state, focus: null };
    default:
      return state;
  }
};

/** @jsx jsx */
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Flex, jsx } from 'theme-ui';
import { useColors } from '../hooks/useColors';
import { changeLocale } from '../state/session';
import Button from './Button';
import { CaretIcon } from './Icon';

const LanguageLink = ({ locale, active, paths, darkMode, color, ...props }) => {
  const { hiContrast } = useColors(darkMode);

  return (
    <Link to={`${paths?.[locale]}`} style={{ textDecoration: 'none' }}>
      <Button
        variant="plain"
        sx={{
          position: 'relative',
          fontWeight: active ? 'medium' : 'normal',
          px: 2,
          py: 0,
          color: ['white', null, null, 'primary'],
          ':hover': {
            color: ['blueLighter', null, null, 'primary'],
          },
          ':focus:not(:active)': {
            boxShadow: 'inputFocus',
          },
          '::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: 1,
            bg: 'border',
          },
          ':last-of-type::after': {
            display: 'none',
          },
        }}
        {...props}
      >
        {locale.toUpperCase()}
      </Button>
    </Link>
  );
};

const LanguageDropdown = ({ paths, hideLocales = [], darkMode, color, ...props }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const loc = (i18n.language || 'fi').toUpperCase();
  const node = useRef();

  const toggleOpen = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = (locale) => {
    setLocale(locale);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const setLocale = (locale) => {
    dispatch(changeLocale(locale, paths));
  };

  const languages = useMemo(() => {
    const pagePathLocales = Object.keys(paths);
    return ['fi', 'sv', 'en'].filter((l) => !hideLocales.includes(l) && pagePathLocales.includes(l));
  }, [hideLocales, paths]);

  const langNames = {
    fi: 'suomi',
    sv: 'svenska',
    en: 'english',
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setOpen((open) => !open);
    }
  }, []);

  return (
    <div ref={node} {...props} sx={{ position: 'relative', ...props.sx }}>
      <DropdownToggle
        color={color}
        active={open}
        tabIndex="0"
        onClick={toggleOpen}
        onKeyDown={handleKeyDown}
        theme={props.theme}
        darkMode={props?.darkMode}
      >
        <Box sx={{ display: ['none', 'block'], color }}>{loc}</Box>
      </DropdownToggle>
      {open && (
        <DropdownMenu open={open}>
          {languages.map((lang, index) => (
            <DropdownMenuItem>
              <LanguageLink
                key={lang}
                color={color}
                darkMode={darkMode}
                paths={paths}
                locale={lang}
                active={loc.toLowerCase() === lang}
                onClick={(e) => {
                  handleChange(lang);
                }}
                className="language"
                role="button"
                aria-label={langNames[lang]}
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </div>
  );
};

const DropdownToggle = (props) => {
  return (
    <Flex
      {...props}
      sx={{
        cursor: 'pointer',
        alignItems: 'center',
        color: props?.color,
        mx: 2,
        transition: 'color .25s ease',
        ':hover': {
          color: 'headerHover',
        },

        'svg:last-of-type': {
          transition: 'transform .25s ease',
          transform: props.active ? 'rotate(180deg)' : 'none',
        },
      }}
    >
      {props.children}
      <CaretIcon sx={{ ml: 2 }} />
    </Flex>
  );
};

const DropdownMenu = (props) => (
  <Box
    sx={{
      position: 'absolute',
      top: '100%',
      right: 0,
      bottom: 'auto',
      bg: 'white',
      py: 2,
      mt: 2,
      borderRadius: 1,
      boxShadow: 'dropdown',
    }}
  >
    {props.children}
  </Box>
);

const DropdownMenuItem = React.forwardRef(({ children, onClick, ...props }, ref) => {
  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }, []);
  return (
    <Box
      ref={ref}
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...props}
      sx={{
        cursor: 'pointer',
        px: 3,
        py: 1,
        whiteSpace: 'nowrap',
        color: 'secondary',
        ':hover': { bg: 'blueLighter' },
      }}
    >
      {children}
    </Box>
  );
});

export default LanguageDropdown;

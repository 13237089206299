import buttons from './buttons';
import forms from './forms';
import colors from './colors';
import styles from './styles';

export default {
  useCustomProperties: false,
  breakpoints: ['30em', '48em', '62em', '80em', '105em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'poppins, system-ui, sans-serif',
    heading: 'poppins, system-ui, sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    semibold: 600,
    medium: 500,
    bold: 600,
  },
  fontSizes: [12, 14, 15, 18, 22, 30, 38, 40, 64],
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    heading: '-0.02em',
  },
  radii: [0, 2, 4, '99rem'],
  boxShadow: {
    dropdown: '0 2px 8px rgba(0,0,0,.1)',
    step: 'inset 0 0 0 2px #001E60',
    inputFocus: '0 0 0 1px' + colors.primary,
    inputHover: '0 0 0 1px ' + colors.grayLight,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      mt: 4,
      mb: 3,
    },
    title: {
      fontSize: [4, 5],
    },
  },
  layout: {
    container: {
      maxWidth: 1312,
      px: [3, null, null, 4],
    },
    text: {
      maxWidth: '80ch',
      px: [3, null, null, 4],
    },
    narrow: {
      maxWidth: 1024,
      px: [3, null, null, 4],
    },
    wide: {
      maxWidth: 1680,
      px: [3, null, null, 4],
    },
    semiWide: {
      maxWidth: 1580,
      px: [3, null, null, 4],
    },
    parcel: {
      maxWidth: 1280,
      px: [3, null, null, 4],
    },
  },
  colors,
  buttons,
  forms,
  styles,
};

/** @jsx jsx */
import { Box, jsx, useThemeUI } from 'theme-ui';
import { forwardRef } from 'react';

const Button = forwardRef((props, ref) => {
  const { theme } = useThemeUI();
  const variant = props?.variant || 'primary';
  const style = theme?.buttons?.[variant];

  return (
    <Box
      ref={ref}
      as="button"
      tx="buttons"
      variant={variant}
      {...props}
      sx={{
        ...style,
        ...props?.sx,
      }}
    />
  );
});

export default Button;

import React from 'react';
import { default as ReduxWrapper } from './src/state/ReduxWrapper';
import SessionCheck from './src/components/SessionCheck';
import { Provider as UpgradeProvider } from './src/upgrade';
import { ReturnShipmentProvider } from './src/returnShipment/ReturnContext';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/localization/i18n';
import { store } from './src/state/ReduxWrapper';

export const wrapRootElement = ({ element }) => (
  <ReduxWrapper>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <ReturnShipmentProvider>
          <UpgradeProvider>
            <SessionCheck>{element}</SessionCheck>
          </UpgradeProvider>
        </ReturnShipmentProvider>
      </HelmetProvider>
    </I18nextProvider>
  </ReduxWrapper>
);

export const wrapPageElement = ({ element, props }) => {
  const { location, pageContext, data = {} } = props;
  const contentfulNode = data.contentfulPage || data.contentfulBlogPost || data.contentfulLayout || {};
  let locale = pageContext.locale || contentfulNode.node_locale;
  if (locale && locale.includes('-')) {
    locale = locale.substring(0, locale.indexOf('-'));
  }
  if (locale) {
    store.dispatch({ type: 'SET_LOCALE', locale });
    i18n.changeLanguage(locale);
  } else if (location.pathname === '/preview') {
    // no-op
  } else {
    console.log('locale not set', location, props);
  }

  return <>{element}</>;
};

export const onPrefetchPathname = ({ pathname, loadPage }) => {
  loadPage(pathname);
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

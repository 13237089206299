/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../state/notifications';
import { isBrowser } from '../utils';
import * as api from '../utils/api';

import { setOrderId } from '../utils/order';
import { ServiceCode } from '../state/catalog';
import i18n from '../localization/i18n';

export const STEP = {
  NOT_SET: 0,
  SIZE: 1,
  SERVICES: 2,
  PAYMENT: 3,
  READY: 4,
};

const enabledServices = [
  ServiceCode.Heavy5kg,
  ServiceCode.DeliveryAgreedTime,
  ServiceCode.Delivery8_16,
  ServiceCode.HandleWithCare,
];

export const Context = React.createContext({
  shipment: {},
  origItem: {},
  upgradeData: {},
  products: {},
  isLoading: false,
  step: STEP.NOT_SET,
  startUpgrade: () => {},
  goBack: () => {},
  goNext: () => {},
  selectProduct: () => {},
  toggleService: () => {},
  setRecipient: () => {},
  createOrder: () => {},
});

const loadUpgradeProducts = async (product, services) => {};

const fixP5 = (products) => {
  products.forEach((p) => {
    (p.services || []).forEach((s) => {
      if (s.serviceCode === ServiceCode.Heavy5kg) {
        s.minWeight = s.fromWeightClass;
        s.maxWeight = s.toWeightClass;
      }
    });
  });
};

export const Provider = ({ children }) => {
  const dispatch = useDispatch();
  const [shipment, setShipment] = useState(null);
  const [origItem, setOrigItem] = useState(null);
  const [upgradeData, setUpgradeData] = useState(null);
  const [products, setProducts] = useState(null);
  const [step, setStep] = useState(STEP.NOT_SET);

  const startUpgrade = useCallback(
    async (shipmentNumber) => {
      setShipment(null);
      setOrigItem(null);
      setUpgradeData({});
      setProducts(null);
      setStep(STEP.SIZE);
      try {
        const response = await api.loadProductUpgradeData(shipmentNumber);
        setShipment(response.shipment);
        setOrigItem(response.orderRow);
        fixP5(response.upgrades);
        setProducts(response.upgrades);
      } catch (error) {
        if (error.response?.status === 404) {
          setProducts([]);
          return;
        }
        dispatch(showNotification('genericApiError'));
        setProducts([]);
      }
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    if (step === STEP.SIZE) {
      window.history.back();
    } else {
      setStep((s) => s - 1);
    }
  }, [step, setStep]);

  const goNext = useCallback(() => {
    if (step === STEP.SIZE) {
      if ((upgradeData.product?.services || []).length > 0) {
        setStep(STEP.SERVICES);
      } else {
        setStep(STEP.PAYMENT);
      }
    } else if (step === STEP.SERVICES) {
      setStep(STEP.PAYMENT);
    }
  }, [step, setStep, upgradeData]);

  const selectProduct = useCallback(
    (product) => {
      setUpgradeData({
        ...upgradeData,
        product: {
          ...product,
          services: (product.services || []).filter((s) => enabledServices.includes(s.serviceCode)),
        },
      });
    },
    [upgradeData]
  );

  const toggleService = useCallback(
    (service) => {
      const oldServices = upgradeData.services || [];
      let newServices = oldServices.filter((s) => s.serviceCode !== service.serviceCode);
      if (newServices.length === oldServices.length) {
        // remove incompatible services
        if (service.serviceCode === ServiceCode.Delivery8_16) {
          newServices = newServices.filter((s) => s.serviceCode !== ServiceCode.DeliveryAgreedTime);
        } else if (service.serviceCode === ServiceCode.DeliveryAgreedTime) {
          newServices = newServices.filter((s) => s.serviceCode !== ServiceCode.Delivery8_16);
        }
        newServices.push(service);
      }
      setUpgradeData({ ...upgradeData, services: newServices });
    },
    [upgradeData]
  );

  const setRecipient = useCallback(
    (recipient) => {
      setUpgradeData({ ...upgradeData, recipient });
    },
    [upgradeData]
  );

  const createOrder = useCallback(
    async (paymentOptions) => {
      try {
        const language = i18n.language;
        const response = await api.createUpgradeOrder(origItem.shipmentNumber, upgradeData, paymentOptions, language);
        const { redirectUrl, orderId, transactionId } = response;
        if (redirectUrl) {
          setOrderId(orderId, transactionId);
          isBrowser && window.location.assign(redirectUrl);
          return;
        }
      } catch (error) {
        console.warn('payment failed:', error);
        dispatch(showNotification('genericApiError'));
      }
    },
    [origItem, upgradeData, dispatch]
  );

  return (
    <Context.Provider
      value={{
        shipment,
        origItem,
        upgradeData,
        products,
        isLoading: products === null,
        step,
        startUpgrade,
        goBack,
        goNext,
        selectProduct,
        toggleService,
        setRecipient,
        createOrder,
      }}
    >
      {children}
    </Context.Provider>
  );
};

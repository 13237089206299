/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';

export default (props) => (
  <Container
    sx={{
      maxWidth: 1024,
      fontSize: [3, null, 4],
      textAlign: 'center',
      lineHeight: 1.25,
    }}
  >
    {props.content ? (
      <Box
        dangerouslySetInnerHTML={{
          __html: props.content.childMarkdownRemark.html,
        }}
      />
    ) : (
      props.children
    )}
  </Container>
);
